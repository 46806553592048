<template>
  <div v-if="!item.hidden&&item.children" class="menu-wrapper">

    <template
      v-if="hasOneShowingChild(item.children,item)">
      <app-link :to="resolvePath(item.path)">
        <el-menu-item :index="resolvePath(item.path)" :class="{'submenu-title-noDropdown':!isNest}">
          <item :icon="item.meta.icon"
                :dev="dev"
                :title="item.meta.title"/>
        </el-menu-item>
      </app-link>
    </template>

    <el-submenu v-else :index="resolvePath(item.path)" >
      <template slot="title">
        <item v-if="item.meta" :dev="dev" :icon="item.meta.icon" :title="item.meta.title"/>
      </template>
      <div v-show="!child.hidden" v-for="child in item.children" :key="child.path">
        <sidebar-item
          v-if="child.children&&child.children.length>0"
          :is-nest="true"
          :item="child"
          :key="child.path"
          :base-path="resolvePath(child.path)"
          class="nest-menu"/>
        <app-link v-else :to="resolvePath(child.path)" :disabled="child.disabled" :key="child.name" >
          <el-menu-item :index="resolvePath(child.path)"  :disabled="child.disabled">
            <item v-if="child.meta" :dev="dev" :icon="child.meta.icon"  :title="child.meta.title"/>
          </el-menu-item>
        </app-link>
      </div>
    </el-submenu>

  </div>
</template>

<script>
  import path from 'path'
  import { isExternal } from '@/utils'
  import Item from './Item'
  import AppLink from './Link'

  export default {
    name: 'SidebarItem',
    components: { Item, AppLink },
    props: {
      // route object
      item: {
        type: Object,
        required: true
      },
      isNest: {
        type: Boolean,
        default: false
      },
      basePath: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        onlyOneChild: null,
        dev: true
      }
    },
    mounted() {
      // console.log(this.item)
      let env = process.env.NODE_ENV
      //区分运行环境
      if (env === 'development') {
        this.dev = true
      } else if (env === 'test') {
        this.dev = true
      } else {
        this.dev = false
      }
    },
    methods: {
      hasOneShowingChild(children, parent) {
        // debugger
        const showingChildren = children.filter(item => {
          if (item.hidden) {
            return false
          } else {
            // Temp set(will be used if only has one showing child)
            this.onlyOneChild = item
            return true
          }
        })

        // When there is only one child router, the child router is displayed by default
        if (showingChildren.length === 1) {
          return false
        }

        // Show parent if there are no child router to display
        if (showingChildren.length === 0) {
          this.onlyOneChild = { ...parent, path: '', noShowingChildren: true }
          return true
        }

        return false
      },
      resolvePath(routePath) {
        if (this.isExternalLink(routePath)) {
          return routePath
        }
        // return routePath
        return path.resolve(this.basePath, routePath)
      },
      isExternalLink(routePath) {
        return isExternal(routePath)
      }
    }
  }
</script>
