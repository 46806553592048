
<template>
  <!-- eslint-disable vue/require-component-is-->
  <component v-bind="linkProps(to,disabled)"  :is="to">
    <slot/>
  </component>
</template>

<script>
import { isExternal } from '@/utils'

export default {
  props: {
    to: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false
    }
  },
  methods: {
    isExternalLink(routePath) {
      return isExternal(routePath)
    },
    linkProps(url) {
      // console.log("applink--"+url)
      if(this.disabled) {
        if (this.isExternalLink(url)) {
          return {
            is: 'span',
            href: url,
            target: '_blank',
            rel: 'noopener'
          }
        }
        return {
          is: 'router-link',
          to: ''
        }
      } else {
        if (this.isExternalLink(url)) {
          return {
            is: 'a',
            href: url,
            target: '_blank',
            rel: 'noopener'
          }
        }
        return {
          is: 'router-link',
          to: url
        }
      }
     
    }
  }
}
</script>
