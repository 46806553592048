import { list, getSupplyAreaByAreaCode, areaCurrentClient } from '@/api/bas/area'
import { getAreaCodeList } from '@/api/sys/user'

const area = {
  state: {
    area: [],
    supplyArea: [],
    userArea: [],
  },
  actions: {
    areas({commit, state}) {
      return new Promise((resolve, reject) => {
        if (state.area == null || state.area.length === 0) {
          list().then(res => {
            commit('SET_AREA', res.data)
            resolve(state.area);
          }).catch(e => {
            reject(e)
          })
        } else {
          resolve(state.area)
        }
      })
    },
    getSupplyArea({commit, state}) {
      if (state.area == null || state.area.length === 0) {
        getSupplyAreaByAreaCode().then(res => {
          commit('setSupplyArea', res.data)
        }).catch(err => {
          console.log(err)
        })
      }
    },
    getUserArea({commit, state}, data) {
      if (state.area == null || state.area.length === 0) {
        getAreaCodeList(data).then(res => {
          commit('setUserArea', res.data)
        }).catch(err => {
          console.log(err)
        })
      }
    },
    resetArea({commit, state}) {
      return new Promise((resolve, reject) => {
        if (state.area == null || state.area.length === 0) {
          list().then(res => {
            commit('SET_AREA', res.data)
            resolve(state.area);
          }).catch(e => {
            reject(e)
          })
        } else {
          resolve(state.area)
        }
      })
    },
    resetSupplyArea({commit, state}) {
      getSupplyAreaByAreaCode().then(res => {
        commit('setSupplyArea', res.data)
      }).catch(err => {
        console.log(err)
      })
    },
    resetUserArea({commit, state}) {
      getAreaCodeList().then(res => {
        commit('setUserArea', res.data)
      }).catch(err => {
        console.log(err)
      })
    },
  },
  mutations: {
    SET_AREA: (state, data) => {
      state.area = data
    },
    setSupplyArea: (state, data) => {
      state.supplyArea = data
    },
    setUserArea: (state, data) => {
      state.userArea = data
    }
  }
}

export default area
