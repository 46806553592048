<template>
  <el-scrollbar ref="scrollContainer" :vertical="false" class="scroll-container"
                @wheel.native="handleScroll">
    <slot/>
  </el-scrollbar>
</template>

<script>
  const tagAndTagSpacing = 4 // tagAndTagSpacing

  export default {
    name: 'ScrollPane',
    data() {
      return {
        left: 0
      }
    },
    methods: {
      handleScroll(e) {

        const eventDelta = e.wheelDelta || -e.deltaY * 40
        const $scrollWrapper = this.$refs.scrollContainer.$refs.wrap
        //
        if (e.wheelDelta > 0 && $scrollWrapper.scrollLeft == 0) {
          return
        }
        // console.log($scrollWrapper.scrollLeft);
        let next = $scrollWrapper.scrollLeft;

        // console.log((next == $scrollWrapper.scrollLeft) + ',' + next + ',' + $scrollWrapper.scrollLeft);

        $scrollWrapper.scrollLeft = $scrollWrapper.scrollLeft - eventDelta
        if (next === $scrollWrapper.scrollLeft) {
          return
        }
        e.preventDefault();
      },
      scrollDown() {
        let that = this;
        setTimeout(function () {
          that.$refs['scrollContainer'].wrap.scrollLeft = that.$refs['scrollContainer'].wrap.scrollWidth - 100
        }, 50);
      },
      moveToTarget(currentTag) {
        const $container = this.$refs.scrollContainer.$el
        const $containerWidth = $container.offsetWidth
        const $scrollWrapper = this.$refs.scrollContainer.$refs.wrap
        const tagList = this.$parent.$refs.tag

        let firstTag = null
        let lastTag = null
        let prevTag = null
        let nextTag = null

        // find first tag and last tag
        if (tagList == null) {
          return;
        }
        if (tagList.length > 0) {
          firstTag = tagList[0]
          lastTag = tagList[tagList.length - 1]
        }

        // find preTag and nextTag
        for (let i = 0; i < tagList.length; i++) {
          if (tagList[i] === currentTag) {
            if (i === 0) {
              nextTag = tagList[i].length > 1 && tagList[i + 1]
            } else if (i === tagList.length - 1) {
              prevTag = tagList[i].length > 1 && tagList[i - 1]
            } else {
              prevTag = tagList[i - 1]
              nextTag = tagList[i + 1]
            }
            break
          }
        }

        if (firstTag === currentTag) {
          $scrollWrapper.scrollLeft = 0
        } else if (lastTag === currentTag) {
          $scrollWrapper.scrollLeft = $scrollWrapper.scrollWidth - $containerWidth
        } else {
          // the tag's offsetLeft after of nextTag
          const afterNextTagOffsetLeft = nextTag.$el.offsetLeft + nextTag.$el.offsetWidth + tagAndTagSpacing

          // the tag's offsetLeft before of prevTag
          const beforePrevTagOffsetLeft = prevTag.$el.offsetLeft - tagAndTagSpacing

          if (afterNextTagOffsetLeft > $scrollWrapper.scrollLeft + $containerWidth) {
            $scrollWrapper.scrollLeft = afterNextTagOffsetLeft - $containerWidth
          } else if (beforePrevTagOffsetLeft < $scrollWrapper.scrollLeft) {
            $scrollWrapper.scrollLeft = beforePrevTagOffsetLeft
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .scroll-container {
    white-space: nowrap;
    position: relative;
    overflow: hidden;
    width: 100%;

    :deep {
      .el-scrollbar__bar {
        bottom: 0px;
      }

      .el-scrollbar__wrap {
        margin-bottom: 0px !important;
      }

      .el-scrollbar__thumb {
        display: none;
      }
    }
  }

</style>
