import router from './router'
import store from './store'
import NProgress from 'nprogress' // Progress 进度条
import 'nprogress/nprogress.css'// Progress 进度条样式
import { Message } from 'element-ui'
import { getToken } from '@/utils/auth' // 验权

const whiteList = ['login'] // 不重定向白名单
router.beforeEach((to, from, next) => {
  NProgress.start()
  console.log(to)
  if (getToken()) {
    if (to.path.indexOf('/login') > -1) {
      next()
      NProgress.done() // if current page is dashboard will not trigger	afterEach hook, so manually handle it
      if (window._hmt) {
        window._hmt.push(['_trackPageview', to.fullPath])
      }
    } else {
      // console.log("length:"+store.getters.roles.length)
      if (store.getters.roles.length === 0) {
        store.dispatch('GetInfo').then(res => { // 拉取用户信息
          //console.log("---> GetInfo <----")
          console.log(store.getters.addRouters)
          store.getters.addRouters.forEach(function(route){
            router.addRoute(route)
          });
          console.log(router)
          // router.addRoutes(store.getters.addRouters)
          console.log(to)
          next({
            ...to
          })
          if (window._hmt) {
            window._hmt.push(['_trackPageview', to.fullPath])
          }
          // next() // hack方法 确保addRoutes已完成 ,set the replace: true so the navigation will not leave a history record
        }).catch((err) => {
          console.log(err)
          store.dispatch('FedLogOut').then(() => {
            Message.error(err || 'Verification failed, please login again')
            next({ path: '/login' })
          })
        })
      } else {
        next()
        if (window._hmt) {
          window._hmt.push(['_trackPageview', to.fullPath])
        }
      }
    }
  } else {
    let data = to.path.split('/')
    if (data.indexOf('login') > -1) {
      next()
    } else {
      // 否则全部重定向到登录页
      next(`/login`)
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done() // 结束Progress
})
