import request from '@/utils/request'

/**
 * 保存或更新
 * @param form
 */
export function saveOrUpdate(form) {
  return request({
    url: `/wms-web/carrier/saveOrUpdate`,
    method: 'post',
    data: form
  })
}

/**
 * 判断承运人代码是否重复
 * @param carrier
 */
export function findCarrier(carrier) {
  return request({
    url: `/wms-web/carrier/find`,
    method: 'get',
    params: {carrier}
  })
}

/**
 * 查找左右承运人
 */
export function findCarriers() {
  return request({
    url: `/wms-web/carrier/`,
    method: `get`
  })
}

/**
 * 通过承运人代码，精准查询承运人，详情界面
 * @param carrier
 */
export function getCarrier(carrier) {
  return request({
    url: `/wms-web/carrier/getCarrier`,
    method: 'get',
    params: {carrier}
  })
}

/**
 * 承运人类型
 */
export function getCarrierType(data) {
  return request({
    url: `/wms-web/carrier/getCarrierType`,
    method: 'post',
    data
  })
}

/**
 * 无法送达的省
 */
export function getAddress(data) {
  return request({
    url: `/wms-web/carrier/getAddress`,
    method: 'post',
    data
  })
}



