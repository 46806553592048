<template>
  <wms-form-item v-if="span == 0"
    :prop='prop'
    :label='label'
    :label-width='labelWidth'
    :required='required'
    :rules='rules'
    :error='error'
    :show-message='showMessage'
    :inline-message='inlineMessage'
    :size='formItemSize'>
    <el-date-picker
      style="width: 100%"
      ref='wmsDatePicker'
      @change="dateChange"
      @blur="dateBlur"
      @focus="dateFocus"
      :value='value'
      @input="val => $emit('input', val)"
      :readonly='readonly'
      :disabled='disabled'
      :editable='editable'
      :clearable='clearable'
      :size='size'
      :placeholder='placeholder'
      :start-placeholder='startPlaceholder'
      :end-placeholder='endPlaceholder'
      :type='type'
      :format='format'
      :align='align'
      :popper-class='popperClass'
      :picker-options='pickerOptions'
      :range-separator='rangeSeparator'
      :default-value='defaultValue'
      :default-time='defaultTime'
      :value-format='valueFormat'
      :name='name'
      :unlink-panels='unlinkPanels'
      :prefix-icon='prefixIcon'
      :clear-icon='clearIcon'
      :validate-event='validateEvent'
    >
    </el-date-picker>
  </wms-form-item>
  <el-col v-else :span="span">
    <wms-form-item
      :prop='prop'
      :label='label'
      :label-width='labelWidth'
      :required='required'
      :rules='rules'
      :error='error'
      :show-message='showMessage'
      :inline-message='inlineMessage'
      :size='formItemSize'>
      <el-date-picker
        style="width: 100%"
        ref='wmsDatePicker'
        @change="dateChange"
        @blur="dateBlur"
        @focus="dateFocus($event, value)"
        :value='value'
        @input="val => $emit('input', val)"
        :readonly='readonly'
        :disabled='disabled'
        :editable='editable'
        :clearable='clearable'
        :size='size'
        :placeholder='placeholder'
        :start-placeholder='startPlaceholder'
        :end-placeholder='endPlaceholder'
        :type='type'
        :format='format'
        :align='align'
        :popper-class='popperClass'
        :picker-options='pickerOptions'
        :range-separator='rangeSeparator'
        :default-value='defaultValue'
        :default-time='defaultTime'
        :value-format='valueFormat'
        :name='name'
        :unlink-panels='unlinkPanels'
        :prefix-icon='prefixIcon'
        :clear-icon='clearIcon'
        :validate-event='validateEvent'
      >
      </el-date-picker>
    </wms-form-item>
  </el-col>
</template>

<script>
export default {
  props: {
    value: {// 绑定值
      required: true
    },
    span: {// 当不为0时代表使用栅格
      type: Number,
      default: 0
    },
    // form-item
    prop: {// 使用表单方法时必填  传入Form组件的model中的字段
      type: String
    },
    label: {// 标签文本
      type: String
    },
    labelWidth: {// 表单域标签的的宽度，例如 '50px'。支持 auto。
      type: String
    },
    required: {// 是否必填，如不设置，则会根据校验规则自动生成
      type: Boolean,
      default: false
    },
    rules: {// 表单验证规则
      type: [Object, Array]
    },
    error: {// 表单域验证错误信息, 设置该值会使表单验证状态变为error，并显示该错误信息
      type: String
    },
    showMessage: {// 是否显示校验错误信息
      type: Boolean,
      default: true
    },
    inlineMessage: {// 以行内形式展示校验信息
      type: Boolean,
      default: false
    },
    formItemSize: {// 用于控制该表单域下组件的尺寸	medium / small / mini
      type: String
    },
    // datePicker
    readonly: {// 完全只读
      type: Boolean,
      default: false
    },
    disabled: {// 禁用
      type: Boolean,
      default: false
    },
    editable: {// 文本框可输入
      type: Boolean,
      default: true
    },
    clearable: {// 是否显示清除按钮
      type: Boolean,
      default: true
    },
    size: {// 输入框尺寸	 large, small, mini
      type: String,
    },
    placeholder: {// 非范围选择时的占位内容
      type: String
    },
    startPlaceholder: {// 范围选择时开始日期的占位内容
      type: String,
      default: '开始时间'
    },
    endPlaceholder: {// 范围选择时结束日期的占位内容
      type: String,
      default: '结束时间'
    },
    type: {// 显示类型	year/month/date/dates/ week/datetime/datetimerange/ daterange/monthrange
      type: String,
      default: 'date'
    },
    format: {// 显示在输入框中的格式	见element日期格式// https://element.eleme.cn/#/zh-CN/component/date-picker#ri-qi-ge-shi
      type: String,
      default: 'yyyy-MM-dd HH:mm'
    },
    align: {// 对齐方式	  left, center, right
      type: String,
      default: 'left'
    },
    popperClass: {// DatePicker 下拉框的类名
      type: String
    },
    pickerOptions: {// 当前时间日期选择器特有的选项
      type: Object,
      // default: () => {
      //   return {
          //注释依次为 说明  类型  可选值  默认值
          // shortcuts: [{// 设置快捷选项，需要传入 { text, onClick } 对象用法参考 demo 或下表  object[]  -  -
          //   text //标题文本  string  -  -
          //   onClick //选中后的回调函数，参数是 vm，可通过触发 'pick' 事件设置选择器的值。例如 vm.$emit('pick', new Date()) function  -  -
          // }],
          // disabledDate //设置禁用状态，参数为当前日期，要求返回 Boolean  function  -  -
          // cellClassName //设置日期的 className  function(Date)  -  -
          // firstDayOfWeek //周起始日	 Number  1到7  7
          // onPick //选中日期后会执行的回调，只有当 daterange 或 datetimerange 才生效	 Function({ maxDate, minDate })  -   -
      //   }
      // }
    },
    rangeSeparator: {// 选择范围时的分隔符
      type: String,
      default: '-'
    },
    defaultValue: {// 可选，选择器打开时默认显示的时间
      type: [Date, String]
    },
    defaultTime: {// 范围选择时选中日期所使用的当日内具体时刻
      type: [String, Array]
    },
    valueFormat: {// 可选，绑定值的格式。不指定则绑定值为 Date 对象	见element日期格式// https://element.eleme.cn/#/zh-CN/component/date-picker#ri-qi-ge-shi
      type: String,
      default: 'yyyy-MM-dd HH:mm'
    },
    name: {// 原生属性
      type: String
    },
    unlinkPanels: {// 在范围选择器里取消两个日期面板之间的联动
      type: Boolean,
      default: false
    },
    prefixIcon: {// 自定义头部图标的类名
      type: String,
      default: 'el-icon-date'
    },
    clearIcon: {// 自定义清空图标的类名
      type: String,
      default: 'el-icon-circle-close'
    },
    validateEvent: {// 输入时是否触发表单的校验
      type: Boolean,
      default: true
    }
  },
  methods: {
    dateChange(val) {
      this.$emit('change', val)
    },
    dateBlur(val) {
      this.$emit('blur', val)
    },
    dateFocus(e, value) {
      if (!value && this.defaultValue) {
        this.$emit('input', this.defaultValue)
        this.$emit('change', this.defaultValue)
      }
      this.$emit('focus', e)
    },
    focus() {
      this.$refs.wmsDatePicker.focus()
    },
    blur() {
      this.$refs.wmsDatePicker.pickerVisible = false
    }
  }
}
</script>

<style>

</style>
