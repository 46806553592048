import { skuApi } from "@/api/bas/sku"

const sku = {
    state: {
        skuList:[],
    },
    actions:{
        getSku({commit, state},params){
            return new Promise((resolve, reject) => {
                if(params.skuOrName) 
                {
                    skuApi(params).then(res => {
                        commit('SET_SKU', res.data)
                        resolve(state.skuList);
                        }).catch(e => {
                        reject(e)
                        })
                } else {
                    resolve([])
                }
                })
        }
    },
    mutations: {
        SET_SKU:(state, data) =>  {
            state.skuList = data  
        }
    }
    
}

export default sku