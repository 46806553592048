import request from '@/utils/request'

// import Layout from '../views/layout/Layout'


export function login(data) {
  return request({
    url: `/wms-web/sys/user/supplierLogin`,
    method: 'post',
    data: data
  })
}

export function getInfo(token) {
  return request({
    url: `/wms-web/sys/user/info`,
    method: 'get',
    params: {token}
  })
}

export function logout() {
  return request({
    url: `/wms-web/user/logout`,
    method: 'post'
  })
}

export function alertPwd(form) {
  return request({
    url: `/wms-web/sys/user/alertPwd`,
    method: 'post',
    data: form,
  })
}

export function resetPwd(userId) {
  return request({
    url: `/wms-web/sys/user/resetPwd`,
    method: 'post',
    params: {userId: userId},
  })
}

// 登录后获取仓库列表
export function getAreaCodeList(data) {
  return request({
    url: `/wms-web/sys/user/getAreaCodeList`,
    method: 'post',
    data
  })
}

// 登录后切换仓库
export function switchAreaCode(data) {
  return request({
    url: `/wms-web/sys/user/switchAreaCode`,
    method: 'post',
    data
  })
}

// 修改头像
export function alertHeadImg(data) {
  return request({
    url: `/wms-web/sys/user/alertHeadImg`,
    method: 'post',
    data
  })
}

// 获取二维码
export function getLoginQrcode(data) {
  return request({
    url: `/wms-web/sys/user/getLoginQrcode`,
    method: 'post',
    data
  })
}

// 获取二维码扫描结果
export function checkByQrcodeLogin(data) {
  return request({
    url: `/wms-web/sys/user/checkByQrcodeLogin`,
    method: 'post',
    data
  })
}
// 获取路由
export function getRouteList(data) {
  return request({
    url: `/wms-web/menuSupplierConfig/listOption`,
    method: 'post',
    data
  })
}


