import { Message } from 'element-ui'
import XLSX from 'xlsx'

const bmlMessage = function(data) {
  let option = {
    message: '',
    type: 'info',
    iconClass: '',
    dangerouslyUseHTMLString: true,
    customClass: '',
    duration: 2000,
    showClose: true,
    center: false,
    onClose: null,
    offset: 20,
  }
  if (typeof data === 'string') {
    option.message = data
    Message(option)
  } else if (typeof data === 'object') {
    for(let x in data) {
      option[x] = data [x]
    }
    Message(option)
  }
}
let typeArray = ['success', 'warning', 'info', 'error']
typeArray.forEach(type => {
  bmlMessage[type] = options => {
    if (typeof options === 'string') {
      options = {
        message: options
      }
    }
    options.type = type
    return bmlMessage(options)
  }
})

const clone = (data) => {
  var o;
  if (typeof data == "object") {
    if (data === null) {
      o = null;
    } else {
      if (data instanceof Array) {
        o = [];
        for (var i = 0, len = data.length; i < len; i++) {
          o.push(clone(data[i]));
        }
      } else {
        o = {};
        for (var j in data) {
          o[j] = clone(data[j]);
        }
      }
    }
  } else {
    o = data;
  }
  return o;
}

const download = (text,res) => {
  const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' }); //application/vnd.openxmlformats-officedocument.spreadsheetml.sheet这里表示xlsx类型
  let filename = text;
  if ('download' in document.createElement('a')) {
      const downloadElement = document.createElement('a');
      let href = '';
      if(window.URL) href = window.URL.createObjectURL(blob);
      else href = window.webkitURL.createObjectURL(blob);
  　　 downloadElement.href = href;
  　　 downloadElement.download = filename;
  　　 document.body.appendChild(downloadElement);
  　　 downloadElement.click();
  　　 if(window.URL) window.URL.revokeObjectURL(href);
      else window.webkitURL.revokeObjectURL(href);
      document.body.removeChild(downloadElement);
  } else {
      navigator.msSaveBlob(blob, filename);
  }
}

const paramsToUrl = (obj) => {
  let str = ''
  Object.keys(obj).forEach(key => {
    str += key + '=' + obj[key] + '&'
  })
  return str.substring(0, str.length - 1)
}

const arraySort = (arr, key) => {// < 数组按key值大小排序 key不传时按数组值大小
  let newArr = arr.sort((a, b) => {
    let valA = key ? a[key] : a
    let valB = key ? b[key] : b
    if (valA > valB) {
      return 1
    } else if (valA < valB) {
      return -1
    } else {
      return 0
    }
  })
  return newArr
}


const webImport = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = e => {
      const data = e.target.result
      const fixedData = fixData(data)
      const workbook = XLSX.read(btoa(fixedData), { type: 'base64' })
      const firstSheetName = workbook.SheetNames[0]
      const worksheet = workbook.Sheets[firstSheetName]
      const header = getHeaderRow(worksheet)
      const results = XLSX.utils.sheet_to_json(worksheet)
      resolve({ header, results })
    }
    reader.readAsArrayBuffer(file)
  })
}

const fixData = (data) => {
  let o = ''
  let l = 0
  const w = 10240
  for (; l < data.byteLength / w; ++l) o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w, l * w + w)))
  o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)))
  return o
}

const getHeaderRow = (sheet) => {
  const headers = []
  const range = XLSX.utils.decode_range(sheet['!ref'])
  let C
  const R = range.s.r
  /* start in the first row */
  for (C = range.s.c; C <= range.e.c; ++C) { /* walk every column in the range */
    const cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })]
    /* find the cell in the first row */
    let hdr = 'UNKNOWN ' + C // <-- replace with your desired default
    if (cell && cell.t) hdr = XLSX.utils.format_cell(cell)
    headers.push(hdr)
  }
  return headers
}

const formatDate = (date) => {
  let myyear = date.getFullYear();
  let mymonth = date.getMonth()+1;
  let myweekday = date.getDate();
  if(mymonth < 10){
      mymonth = "0" + mymonth;
  }
  if(myweekday < 10){
      myweekday = "0" + myweekday;
  }
  return (myyear+"-"+mymonth + "-" + myweekday);
}

const getTomorrowDate = () => {
  let date = new Date();
  date.setTime(date.getTime() + 3600 * 1000 * 24);
  return timeFormat(date)
}

const getWeekDate = (date) => {
  let nowYear = date.getFullYear()
  let nowMonth = date.getMonth()
  let nowDay = date.getDate()
  let day = date.getDay()
  let nowDayOfWeek = day === 0 ? 7 : day
  let weekStartDate = new Date(new Date(nowYear, nowMonth, nowDay - nowDayOfWeek).getTime() + 60 * 60 * 24 * 1000)
  let weekEndDate = new Date(nowYear, nowMonth, nowDay + (7 - nowDayOfWeek))
  return [formatDate(weekStartDate), formatDate(weekEndDate)]
}
const getMonthDays = (myMonth) => {
  let nowYear = new Date().getFullYear()
  var monthStartDate = new Date(nowYear, myMonth, 1);
  var monthEndDate = new Date(nowYear, myMonth + 1, 1);
  var days = (monthEndDate - monthStartDate)/(1000 * 60 * 60 * 24);
  return days;
}
const getMonthDate = (month) => {
  let date = new Date()
  let nowYear = date.getFullYear()
  let nowMonth = month - 1 < 0 ? 11 : month - 1
  var monthStartDate = new Date(nowYear, nowMonth, 1)
  var monthEndDate = new Date(nowYear, nowMonth, getMonthDays(nowMonth))
  return [formatDate(monthStartDate), formatDate(monthEndDate)]
}

const numStrAdd = (str, num) => {
  let arr = str.split('').reverse()
  let newArr = []
  arr.forEach((item, index) => {
    let newNum = parseInt(item) + num
    newArr.push(newNum%10 + '')
    num = parseInt(newNum/10)
  })
  if (num) {
    return num + newArr.reverse().join('')
  } else {
    return newArr.reverse().join('')
  }
}

const timeFormat = (date) => {
  return date.getFullYear() + (date.getMonth() + 1 > 9 ? "-" : "-0") + (date.getMonth() + 1) + (date.getDate() > 9 ? "-" : "-0") + date.getDate()
}

const getAroundToday = () => {
  let today = new Date()
  return [timeFormat(today), timeFormat(today)]
}

const getAroundYesterday = () => {
  const end = new Date();
  const start = new Date();
  start.setTime(start.getTime() - 3600 * 1000 * 24);
  return [timeFormat(start), timeFormat(end)]
}

const getAroundWeekday = () => {
  let end = new Date();
  let start = new Date();
  start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
  return [timeFormat(start), timeFormat(end)]
}

const getBeforeAndAfterToday = (before, after) => {
  let end = new Date();
  let start = new Date();
  start.setTime(start.getTime() - 3600 * 1000 * 24 * before);
  end.setTime(end.getTime() + 3600 * 1000 * 24 * after);
  return [timeFormat(start), timeFormat(end)]
}

const getAroundMonth = () => {
  let end = new Date();
  let start = new Date();
  start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
  return [timeFormat(start), timeFormat(end)]
}

const onlyNumInput = (val) => {
  let patt = /[1-9][0-9]*/g
  let data = val.match(patt)
  if (data && data.length > 0) {
    return data[0]
  } else {
    return null
  }
}
const stringToNull = (data) => {
  let o;
  if (typeof data === "object") {
    if (data === null) {
        o = data;
    } else {
      if (data instanceof Array) {
        o = [];
        data.forEach(item => {
          o.push(stringToNull(item))
        })
      } else {
        o = {};
        for (let j in data) {
          o[j] = stringToNull(data[j])
        }  
      }
    }
  } else {
    if (data === '') {
      o = null
    } else {
      o = data
    }
  }
  return o;
}

const bolbToFile = (bolb, fileName) => {
  console.log(bolb)
  console.log(fileName)
  bolb.lastModifiedDate = new Date()
  bolb.name = fileName
  return bolb
}

const getBlobUrl = (res) => {// 获取后台返回blob的url
  return new Promise((resolve, reject) => {
    let blob = new Blob([res])
    var reader = new FileReader()
    reader.onload = e => {
      try {// 是否是json数据
        let result = JSON.parse(e.target.result)
        reject(result)
      } catch (err) {// 正常的文件流
        let data = new Blob([res], {type: 'application/pdf;charset=UTF-8'})
        let url = window.URL.createObjectURL(data)
        resolve(url)
      }
    }
    reader.readAsText(blob)
  })
}

const removeAllSpace = (str) => {// 清除字符串空格
  return str.replace(/\s+/g, "")
}

const urlIsPdf = (url) => {// 文件链接后缀为pdf
  let type = url.substring(url.length - 3)
  return type == 'pdf' || type == 'PDF'
}

const qiNiuUpload = (f) => {// 七牛上传
  return new Promise((resolve, reject) => {
    getToken()
    .then(res => {
      if (res.success) {
        let formData = new FormData()
        let time = new Date().getTime()
        formData.append('file', f.file)
        formData.append('token', res.data)
        formData.append('key', time + '-' + f.file.name)
        request.post('https://upload.qiniup.com/', formData)
        .then(qiNiuRes => {
          if (qiNiuRes.key) {
            resolve(`https://wms-file.fuqueyun.com/${time}-${f.file.name}`)
          } else {
            reject('上传失败')
          }
        }).catch(err => {
          reject('上传失败')
        })
      } else {
        reject('上传失败')
      }
    }).catch(err => {
      reject(err)

    })
  })
}

const getStepNum = (min, max) => {
  let arr = []
  let minNum = Number(min)
  let maxNum = Number(max)
  if ((minNum || minNum === 0) && (maxNum || maxNum === 0)) {
    let beginNum, endNum
    if (minNum < maxNum) {
      beginNum = minNum
      endNum = maxNum
    } else {
      beginNum = maxNum
      endNum = minNum
    }
    for (let i = beginNum; i <= endNum; i++) {
      arr.push(i)
    }
  }
  return arr
}


export default {
  bmlMessage,
  clone,
  arraySort,
  download,
  webImport,
  paramsToUrl,
  getTomorrowDate,
  getWeekDate,
  formatDate,
  getMonthDate,
  numStrAdd,
  getAroundToday,
  getAroundWeekday,
  getAroundMonth,
  getAroundYesterday,
  onlyNumInput,
  stringToNull,
  bolbToFile,
  getBlobUrl,
  removeAllSpace,
  urlIsPdf,
  getStepNum,
  getBeforeAndAfterToday,
  timeFormat
}
