import { getRegionLabelList,getShopRemote } from '@/api/bas/guestShop'

const shop = {
  state: {
    guestShopList: [],
    lastAreaList: [],
    regionLabelList: [],
    guestShopList:[]
  },
  actions: {
    getGuestShopList({commit, state}) {
      if (state.guestShopList.length === 0) {
        getShopRemote({search: ''}).then(res => {
          commit('setGuestShopList', res.data);
        }).catch(err => {
          console.log(err)
        })
      }
    },
    resetGuestShopList({commit, state}) {
      getShopRemote({search: ''}).then(res => {
        commit('setGuestShopList', res.data);
      }).catch(err => {
        console.log(err)
      })
    },
    getRegionLabelList({commit, state},areaId = null){
      if (state.regionLabelList.length === 0) {
        getRegionLabelList().then(res => {
          console.log(res)
          commit('setRegionLabelList', res.data);
        }).catch(err => {
          console.log(err)
        })
      }
    },
    resetRegionLabelList({commit, state},areaId = null) {
      getRegionLabelList().then(res => {
        commit('setRegionLabelList', res.data);
      }).catch(err => {
        console.log(err)
      })
    }
  },
  mutations: {
    setGuestShopList: (state, data) => {
      state.guestShopList = data
    },
    setLastAreaList: (state, data) => {
      state.lastAreaList = data
    },
    setRegionLabelList: (state, data) => {
      state.regionLabelList = data
    },
    setGuestShopList: (state, data) => {
      state.guestShopList = data
    },
  }
};

export default shop
