import request from '@/utils/request'

//路线列表
export function getBasRoute(data) {
    return request({
      url: `/wms-web/basRoute/list`,
      method: 'post',
      data: {...data}
    })
  }
