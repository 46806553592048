/**
 * Created by jiachenpan on 16/11/18.
 */

export function parseTime(time, cFormat = 'yyyy-MM-dd hh:mm') {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (('' + time).length === 10) time = parseInt(time) * 1000
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}

export function formatTime(time, option) {
  time = +time * 1000
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日' +
      d.getHours() +
      '时' +
      d.getMinutes() +
      '分'
    )
  }
}

/**
 * 对象是否所有对象都是空
 * params 的所有属性值都不能为空
 * 参数验证
 * if(!this.paramsValidate(params)) return;
 */
export function objIsHasNullPro(params) {
  for (let key in params) {
    if (params[key] || params[key] === 0) {
      return false; // 终止程序
    }
  }
  return true;
}

/**
 * source 初始化表单
 * @param source
 * @param target
 */
export function objEquals(source, target) {
  let ret = {};
  for (let key in source) {
    console.log(source[key] + "  " + target[key]);
    if (key === 'asnId') {
      ret[key] = target[key];
    }
    if (source[key] !== target[key]) {
      ret[key] = target[key];
    }
  }
  console.log(ret);
  return ret;
}

export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}


/**
 * @name  getTableData
 * @desc  纯JS前端分页方法
 * @param  {Number} page 当前页码，默认1
 * @param  {Number} pageSize 每页最多显示条数，默认10
 * @param  {Array} totalData 总的数据集，默认为空数组
 * @return {Object} {
    data, //当前页展示数据，数组
    page, //当前页码
    pageSize, //每页最多显示条数
    length, //总的数据条数
  }
 **/
export function getTableData(page = 1, pageSize = 10, totalData = []){
  const { length } = totalData;
  const tableData = {
    data: [],
    page,
    pageSize,
    length,
  };
  if (pageSize >= length) { //pageSize大于等于总数据长度，说明只有1页数据或没有数据
    tableData.data = totalData;
    tableData.page = 1;//直接取第一页
  } else { //pageSize小于总数据长度，数据多余1页
    const num = pageSize * (page - 1);//计算当前页（不含）之前的所有数据总条数
    if (num < length) { //如果当前页之前所有数据总条数小于（不能等于）总的数据集长度，则说明当前页码没有超出最大页码
      const startIndex = num;//当前页第一条数据在总数据集中的索引
      const endIndex = num + pageSize - 1;//当前页最后一条数据索引
      tableData.data = totalData.filter((_, index) => index >= startIndex && index <= endIndex);//当前页数据条数小于每页最大条数时，也按最大条数范围筛取数据
    } else { //当前页码超出最大页码，则计算实际最后一页的page，自动返回最后一页数据
      const size = parseInt(length / pageSize); //取商
      const rest = length % pageSize; //取余数
      if (rest > 0) { //余数大于0，说明实际最后一页数据不足pageSize，应该取size+1为最后一条的页码
        tableData.page = size + 1;//当前页码重置，取size+1
        tableData.data = totalData.filter((_, index) => index >= (pageSize * size) && index <= length);
      } else if (rest === 0) { //余数等于0，最后一页数据条数正好是pageSize
        tableData.page = size;//当前页码重置，取size
        tableData.data = totalData.filter((_, index) => index >= (pageSize * (size - 1)) && index <= length);
      } //注：余数不可能小于0
    }
  }
  return tableData;
};


/**
 * 计算日期
 * @param n
 * @returns {String}
 */
export function getBeforeDate(strDate,n){//strDate 为字符串日期 如:'2019-01-01' n为你要传入的参数，当前为0，前一天为-1，后一天为1
	var datt = strDate.split('-');//这边给定一个特定时间
	var newDate = new Date(datt[0], datt[1]-1, datt[2]);
	var befminuts = newDate.getTime() + 1000 * 60 * 60 * 24 * parseInt(n);//计算前几天用减，计算后几天用加，最后一个就是多少天的数量
	var beforeDat = new Date;
	beforeDat.setTime(befminuts);
	var befMonth = beforeDat.getMonth()+1;
	var mon = befMonth >= 10 ? befMonth : '0' + befMonth;
	var befDate = beforeDat.getDate();
	var da = befDate >= 10 ? befDate : '0' + befDate;
	var newDate = beforeDat.getFullYear() + '-' + mon + '-' + da;
	console.log(newDate)
	return newDate;
	
 }

