import request from '@/utils/request'

export function getList(params) {
  return request({
    url: `/wms-web/table/list`,
    method: 'get',
    params
  })
}

// 获取用户表头
export function tableColumn(tableName) {
  return request({
    url: `/wms-web/table/column/${tableName}`,
    method: 'get'
  })
}

// 获取原始表头
export function tableAll(tableName) {
  return request({
    url: `/wms-web/table/column/tableAll/${tableName}`,
    method: 'post'
  })
}

// 获取原始查询项
export function formSearchDetail(tableName) {
  return request({
    url: `/wms-web/table/select/${tableName}`,
    method: 'get'
  })
}

// 获取原始查询项
export function formSearchAll(tableName) {
  return request({
    url: `/wms-web/table/select/tableAll/${tableName}`,
    method: 'get'
  })
}
// 获取分类查询条件列表
export function formSearchGroup(tableName) {
  return request({
    url: `/wms-web/table/select/tableGroup/${tableName}`,
    method: 'get'
  })
}
// 修改分类下的查询条件列表
export function updateFormSearchGroup(data) {
  return request({
    url: `/wms-web/table/select/updateTableSelectByParentId`,  
    method: 'post',
    data
  })
}
// 表头显示修改
export function updateTable(data) {
  return request({
    url: `/wms-web/table/column/updateTable`,
    method: 'post',
    data
  })
}

// 查询显示修改
export function updateTableSelect(data) {
  return request({
    url: `/wms-web/table/select/updateTableSelect`,
    method: 'post',
    data
  })
}

// 表头拖拽修改
export function sortTable(data) {
  return request({
    url: `/wms-web/table/column/sortTable`,
    method: 'post',
    data
  })
}

// 表头宽度修改
export function updateTableWidth(data) {
  return request({
    url: `/wms-web/table/column/updateTableWidth`,
    method: 'post',
    data
  })
}
