<template>
  <div class="view">
    <div class="iconView">
      <el-button v-if="type == 'table'" @click="iconClick" icon="el-icon-s-tools">表头设置</el-button>
      <el-button v-if="type == 'formSearch' || type == 'formSearchGroup' " @click="iconClick" icon="el-icon-s-tools">
        <span v-show="typeDetail == 'detail'">表头设置</span>
        <span v-show="typeDetail == null">查询项设置</span>
      </el-button>
      <!-- <i @click="iconClick" class="el-icon-s-tools"></i>
      <span>设置</span> -->
    </div>
    <wms-dialog
      width='680px'
      title='列表字段显示/隐藏 配置'
      :dialogVisible.sync='visible'
      haveFooter>
      <div class="titleExplain"><i class="el-icon-info" style="margin-right: 6px"></i>勾选的列表字段名称将显示在页面中，列表中字段可拖动调整排列顺序</div>
      <el-scrollbar style="height: 260px; background-color: #fafafa; border: 1px solid #e4e4e4e">
        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange" style="margin-left:10px">全选</el-checkbox>
        <el-form :model="form" ref='form' size='mini' style="padding: 10px">
          <el-form-item prop='userTableColumnList' class="autoHeightFormItem">
            <el-checkbox-group v-model="form.userTableColumnList" @change="handleCheckedCitiesChange">
              <el-row>
                <el-col :span='6' v-for="(item, index) in tableList" :key="index" style="padding: 10px 0">
                  <el-checkbox :label='item.keyId'>{{item.columnValue}}</el-checkbox>
                </el-col>
              </el-row>
            </el-checkbox-group>
          </el-form-item>
        </el-form>
      </el-scrollbar>
      <wms-footer slot='dialogFooter' line-height='30px'>
        <el-button @click="submit" type="primary" plain>保存</el-button>
        <el-button @click="visible = false" type="info" plain>取消</el-button>
      </wms-footer>
    </wms-dialog>
    <wms-dialog
      width='680px'
      title='查询项字段显示/隐藏 配置'
      :dialogVisible.sync='searchVisible'
      haveFooter>
      <div class="titleExplain"><i class="el-icon-info" style="margin-right: 6px"></i>勾选的查询字段名称将显示在页面中</div>
      <el-scrollbar style="height: 260px; background-color: #fafafa; border: 1px solid #e4e4e4e">
        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange" style="margin-left:10px">全选</el-checkbox>
        <el-form :model="searchForm" ref='searchForm' size='mini' style="padding: 10px">
          <el-form-item prop='userTableSelectList' class="autoHeightFormItem">
            <el-checkbox-group v-model="searchForm.userTableSelectList" @change="handleCheckedCitiesChange">
              <el-row>
                <el-col :span='6' v-for="(item, index) in searchList" :key="index" style="padding: 10px 0" >
                  <el-checkbox :label='item.keyId'>{{item.columnValue}}</el-checkbox >
                </el-col>
              </el-row>
            </el-checkbox-group>
          </el-form-item>
        </el-form>
      </el-scrollbar>
      <wms-footer slot='dialogFooter' line-height='30px'>
        <el-button @click="searchSubmitGroup" type="primary" v-if="type == 'formSearchGroup'" plain>保存</el-button>
        <el-button @click="searchSubmit"  v-if="type == 'formSearch' " type="primary" plain>保存</el-button>
        <el-button @click="searchVisible = false" type="info" plain>取消</el-button>
        
      </wms-footer>
    </wms-dialog>
  </div>
</template>

<script>
import { tableAll, formSearchAll, updateTable, updateTableSelect, formSearchGroup,updateFormSearchGroup } from '@/api/table'

export default {
  name: 'tableHeader',
  props: {
    title: {// 废弃
      type: String,
      default: '表头字段'
    },
    type: {
      type: String,
      default: 'table'
    },
    typeDetail: {
      type: String,
      default: null
    },
    tableName: {
      type: String
    },
    parentKeyId: {
      type: String
    },
    searchName: {
      type: String
    },
    checkedTitleList: {
      type: Array,
      default: () => []
    },
    filterKeyList: {// 不显示key集合
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      loading: false,
      visible: false,
      checkAll: false,
      isIndeterminate: true,
      searchVisible: false,
      form: {
        userTableColumnList: []
      },
      searchForm: {
        userTableSelectList: []
      },
      tableList: [],
      searchList: [],
    }
  },
  methods: {
      handleCheckAllChange(val) {
        if (this.type == "table") {
          this.form.userTableColumnList = val ? this.tableList.map(item => item.keyId) : [];
          this.checkedTitleList = val ? this.tableList.map(item => item.keyId) : [];
        } else {
          this.searchForm.userTableSelectList = val ? this.searchList.map(item => item.keyId) : [];
          this.checkedTitleList = val ? this.searchList.map(item => item.keyId) : [];
        }
      
        this.isIndeterminate = false;
      },
      handleCheckedCitiesChange(value) {
        if(!this.form.userTableSelectList) {
          return
        }
        let checkedCount = value.length;
        if (this.type == "table") {
          this.checkAll = checkedCount === this.form.userTableSelectList.length;
          this.isIndeterminate = checkedCount > 0 && checkedCount < this.form.userTableSelectList.length;
        } else {
          this.checkAll = checkedCount === this.searchForm.userTableColumnList.length;
          this.isIndeterminate = checkedCount > 0 && checkedCount < this.searchForm.userTableColumnList.length;
        }
      },
    iconClick() {
      if (this.type == "table") {
        this.getTable()
      } else if (this.type == "formSearch") {
        this.getSearch()
      } else if (this.type === "formSearchGroup") {
        this.getSearchGroup()
      }
    },
    getTable() {
      tableAll(this.tableName)
      .then(res => {
        if (res.success) {
          this.visible = true
          this.tableList = res.data.filter(item => this.filterKeyList.indexOf(item.columnKey) == -1)
          this.form.userTableColumnList = this.checkedTitleList
        } else {
          this.$bmlMessage.error(res.msg)
        }
      }).catch(err => {
        this.tableList = []
      })
    },
    getSearchGroup() {
      formSearchGroup(this.parentKeyId)
      .then(res => {
        if(res.success) {
          this.searchVisible = true
          this.searchList = res.data
          this.searchForm.userTableSelectList = this.checkedTitleList
        } else {
          this.$bmlMessage.error(res.msg)
        }
      })
    },
    getSearch() {
      formSearchAll(this.searchName)
      .then(res => {
        let arr = []
        res.data.forEach(item1 => {
          if(item1.list) {
          let key = Object.keys(item1).filter(item2 => item2 != "list")[0]
            item1.list.forEach(item3 => {
              arr.push({
                ...item3,
                parentKeyId: item1[key]
              })
            })
          } else {
            arr = res.data
          }
          
        })
        this.searchVisible = true
        this.searchList = arr
        this.searchForm.userTableSelectList = this.checkedTitleList
      })
    },
    submit() {
      if (this.loading) {
        return
      }
      this.$refs.form.validate()
      .then(res => {
        this.loading = true
        updateTable({
          tableName: this.tableName,
          ...this.form
        }).then(res => {
          this.loading = false
          if (res.success) {
            this.visible = false
            this.$emit('updateTableTitle')
          } else {
            this.$bmlMessage.error(res.msg)
          }
        }).catch(err => {
          this.loading = false
        })
      })
    },
    searchSubmitGroup() {
      if (this.loading) {
        return
      }
      this.$refs.searchForm.validate()
      .then(res => {
        this.loading = true
        let arr = []
        this.searchList.map(item => item.keyId).filter(item1 => {
            if(this.searchForm.userTableSelectList.indexOf(item1) > -1) {
              arr.push(item1)
            }
          })
       
        updateFormSearchGroup({
          tableSelectName: this.searchName,
          parentId: this.parentKeyId,
          userTableSelectList:arr
        }).then(res => {
          this.loading = false
          if (res.success) {
            this.searchVisible = false
            this.$emit('updateSearchTitle')
            this.$refs.wmsContainer.setClientHeight()
          } else {
            this.$bmlMessage.error(res.msg)
          }
        }).catch(err => {
          this.loading = false
        })
      })
    },
    searchSubmit() {
      if (this.loading) {
        return
      }
      this.$refs.searchForm.validate()
      .then(res => {
        this.loading = true
        updateTableSelect({
          tableSelectName: this.searchName,
          ...this.searchForm
        }).then(res => {
          this.loading = false
          if (res.success) {
            this.searchVisible = false
            this.$emit('updateSearchTitle')
            this.$refs.wmsContainer.setClientHeight()
          } else {
            this.$bmlMessage.error(res.msg)
          }
        }).catch(err => {
          this.loading = false
        })
      })
    }
  }
}
</script>

<style scoped lang='scss'>
  .view {
    line-height: 28px;
    // float: right;
    color: #93a2b3;
  }
  // .iconView {
  //   cursor: pointer;
  //   line-height: 28px;
  //   border: 1px solid #dcdfe6;
  //   border-radius: 4px;
  //   padding: 0 10px;
  //   font-size: 14px
  // }
  .titleExplain {
    color: #989898;
    font-size: 14px;
  }
</style>
