const getters = {
  sidebar: state => state.app.sidebar,
  device: state => state.app.device,
  token: state => state.user.token,
  userId: state => state.user.userId,
  clientId: state => state.user.clientId,
  routers: state => state.user.routers,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  // userId: state => state.user.userId,
  areaName: state => state.user.areaName,
  areaCode: state => state.user.areaCode,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  roles: state => state.user.roles,
  alias: state => state.user.alias,
  addRouters: state => state.user.addRouters,
  date: state => state.date,
  selectDate: state => state.common.selectDate,
  common: state => state.common,
  label: state => state.label,
  placeholder: state => state.placeholder,
  enable: state => state.common.enable,
  sortTypeList: state => state.common.sortTypeList,
  customer: state => state.customer.customer,
  carrier: state => state.carrier.carrier,
  location: state => state.location.location,
  reservoir: state => state.reservoir.reservoir,
  area: state => state.area.area,
  asn: state => state.asn,
  users: state => state.user.users
}
export default getters
