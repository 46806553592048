import Layout from '../views/layout/Layout'
import store from '@/store'

import { componentsMap } from '@/router/routerConfig'

/**
*将后台的路由表进行格式化
* @param {*} asyncRouterMap 
*/
export function convertRouter(asyncRouterMap) {
    const accessedRouters = []
    if (asyncRouterMap) {
        asyncRouterMap.forEach(item => {
            var parent = generateRouter(item, true)
            var children = []
            if (item.children) {
                item.children.forEach(child => {
                    children.push(generateRouter(child, false, item.path))
                })
            }
            parent.children = children
            accessedRouters.push(parent)
        })
    }
    accessedRouters.push({ path: '*', redirect: '/404', hidden: true })
    // console.log(accessedRouters);
    return accessedRouters
}

// 将传入的话进行格式化成 路由规则 的格式
function generateRouter(item, isParent, parentPath) {
    let alias = store.getters.alias
    let path
    if (alias) {
        if (isParent) {
            path = `/${alias}${item.path}`
        } else {
            path = `/${alias}${parentPath}/${item.path}`
        }
    } else {
        if (isParent) {
            path = `${item.path}`
        } else {
            path = `${parentPath}/${item.path}`
        }
    }
    var router = {
        path: path,
        // name: item.name,
        name: item.name,
        meta: item.meta,
        hidden:item.hidden>0?false:true,
        // component: isParent ? Layout : () => import(item.component)
        component: isParent ? Layout : componentsMap[item.name]
    }
    return router
}
