<template>
  <wms-form-item v-if="span == 0"
    :prop='prop'
    :label='label'
    :label-width='labelWidth'
    :required='required'
    :rules='rules'
    :error='error'
    :show-message='showMessage'
    :inline-message='inlineMessage'
    :size='formItemSize'
  >
    <el-switch
      ref='wmsSwitch'
      @change="switchChange"
      :value='value'
      @input="val => $emit('input', val)"
      :disabled='disabled'
      :width='width'
      :active-icon-class='activeIconClass'
      :inactive-icon-class='inactiveIconClass'
      :active-text='activeText'
      :inactive-text='inactiveText'
      :active-value='activeValue'
      :inactive-value='inactiveValue'
      :active-color='activeColor'
      :inactive-color='inactiveColor'
      :name='name'
      :validate-event='validateEvent'
    >
    </el-switch>
    <slot></slot>
  </wms-form-item>
  <el-col :span='span' v-else>
    <wms-form-item
      :prop='prop'
      :label='label'
      :label-width='labelWidth'
      :required='required'
      :rules='rules'
      :error='error'
      :show-message='showMessage'
      :inline-message='inlineMessage'
      :size='formItemSize'
    >
      <el-switch
        ref='wmsSwitch'
        @change="switchChange"
        :value='value'
        @input="val => $emit('input', val)"
        :disabled='disabled'
        :width='width'
        :active-icon-class='activeIconClass'
        :inactive-icon-class='inactiveIconClass'
        :active-text='activeText'
        :inactive-text='inactiveText'
        :active-value='activeValue'
        :inactive-value='inactiveValue'
        :active-color='activeColor'
        :inactive-color='inactiveColor'
        :name='name'
        :validate-event='validateEvent'
      >
      </el-switch>
      <slot></slot>
    </wms-form-item>
  </el-col>
</template>

<script>
export default {
  props: {
    value: {// 绑定值
      required: true
    },
    span: {// 当不为0时代表使用栅格
      type: Number,
      default: 0
    },
    // form-item
    prop: {// 使用表单方法时必填  传入Form组件的model中的字段
      type: String
    },
    label: {// 标签文本
      type: String
    },
    labelWidth: {// 表单域标签的的宽度，例如 '50px'。支持 auto。
      type: String
    },
    required: {// 是否必填，如不设置，则会根据校验规则自动生成
      type: Boolean,
      default: false
    },
    rules: {// 表单验证规则
      type: [Object, Array]
    },
    error: {// 表单域验证错误信息, 设置该值会使表单验证状态变为error，并显示该错误信息
      type: String
    },
    showMessage: {// 是否显示校验错误信息
      type: Boolean,
      default: true
    },
    inlineMessage: {// 以行内形式展示校验信息
      type: Boolean,
      default: false
    },
    formItemSize: {// 用于控制该表单域下组件的尺寸	medium / small / mini
      type: String
    },
    // switch
    disabled: {// 是否禁用
      type: Boolean,
      default: false
    },
    width: {// switch 的宽度（像素）
      type: Number,
      default: 40
    },
    activeIconClass: {// switch 打开时所显示图标的类名，设置此项会忽略 active-text
      type: String
    },
    inactiveIconClass: {// switch 关闭时所显示图标的类名，设置此项会忽略 inactive-text
      type: String
    },
    activeText: {// switch 打开时的文字描述
      type: String
    },
    inactiveText: {// switch 关闭时的文字描述
      type: String
    },
    activeValue: {// switch 打开时的值
      type: [Boolean, String, Number],
      default: () => {
        return 1
      }
    },
    inactiveValue: {// switch 关闭时的值
      type: [Boolean, String, Number],
      default: () => {
        return 0
      }
    },
    activeColor: {// switch 打开时的背景色
      type: String,
      default: '#409EFF'
    },
    inactiveColor: {// switch 关闭时的背景色
      type: String,
      default: '#C0CCDA'
    },
    name: {// switch 对应的 name 属性
      type: String
    },
    validateEvent: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    switchChange(val) {
      this.$emit('change', val)
    },
    focus() {
      this.$refs.wmsSwitch.focus()
    }
  }
}
</script>

<style>

</style>
