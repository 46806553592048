<script>
  export default {
    name: 'MenuItem',
    functional: true,
    props: {
      icon: {
        type: String,
        default: ''
      },
      title: {
        type: String,
        default: ''
      },
      dev:{
        title: {
          type: Boolean,
          require: true
        },
      }
    },
    render(h, context) {
      const {icon, title, dev} = context.props
      const vnodes = []

      if (icon) {
        // console.log(dev)
        if (dev) {
          vnodes.push(<svg-icon icon-class={icon}/>)
        } else {
          vnodes.push(<svg-icon icon-class={icon}/>)
        }

        // vnodes.push(<svg-icon icon-class={icon}/>)

      }

      if (title) {
        if (dev) {
          vnodes.push(<span slot='title'>{(title)}</span>)
        } else {
          vnodes.push(<span slot='title'>{(title)}</span>)
        }

        // vnodes.push(<span slot='title'>{(title)}</span>)

      }
      return vnodes
    }
  }
</script>
