import request from '@/utils/request'

//用户表格 分页  貌似没有用到
export function userList(current = 1, size = 50) {
  return request({
    url: `/wms-web/sys/users/${current}/${size}`,
    method: 'get'
  })
}
// 用户 新增或者保存
export function saveOrUpdateUser(user){
  return request({
    url: `/wms-web/sys/user/saveOrUpdate`,
    method:'post',
    data:user
  })
}

export function findByUserId(userId){
  return request({
    url: `/wms-web/sys/user/roles/${userId}`,
    method:'get',
    data:userId
  })
}
export function validatorUserName(userName){
  return request({
    url: `/wms-web/sys/user/name/${userName}`
  })
}

//获取该仓库下的全部用户
export function findUsers(){
  return request({
    url: `/wms-web/sys/user/list`,
    method: 'post'
  })
}
// 根据输入的 登录名 来查找 仓库列表
export function getLoginAreaCode(data){
  return request({
    // url: `/wms-web/sys/user/getAreaCode?user=${data.user}`,
    url: `/wms-web/sys/user/getLoginAreaCode`,
    method: 'post',
    data
  })
}


// 登录后获取仓库列表
export function getAreaCodeList(data) {
  return request({
    url: `/wms-web/sys/user/getAreaCodeList`,
    method: 'post',
    data
  })
}

// 获取当前用户拥有的仓库数量
export function findAreaCount(){
  return request({
    url: `/wms-web/sys/user/getAreaCount`,
    method: 'post'
  })
}

// 修改用户输入框权限
export function updatePageField(data){
  return request({
    url: `/wms-web/sys/user/updatePageField`,
    method: 'post',
    data
  })
}

// 自动登录
export function autoLogin({flag}){
  return request({
    url: `/wms-web/sys/user/autoLogin?flag=${flag}`,
    method: 'get',
  })
}

// 查询供应商角色
export function getSupplierRoles(data){
  return request({
    url: `/wms-web/role/getSupplierRoles`,
    method: 'post',
    data
  })
}
