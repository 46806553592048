<template>
<div style='background-color:#304156;'>
  <div style="width:178px;margin:0px auto;text-align:center;margin-top:10px" v-if='avatar'>
    <el-image
      style="width: 150px; height: 75px;border-radius: 5px;"
      :src="avatar">
    </el-image>
    <p style="color:#fff;font-size:16px;">{{areaName}}</p>
  </div>
  <el-scrollbar wrap-class="scrollbar-wrapper" :style="{height: avatar?clientHeight-120 + 'px':clientHeight+'px'}">
    <el-menu
      :show-timeout="200"
      :default-active="$route.path"
      :collapse="isCollapse"
      mode="vertical"
      background-color="#304156"
      text-color="#bfcbd9"
      active-text-color="#409EFF"
      :unique-opened="true">
      <!-- <div style="width:178px;margin:0px auto;text-align:center;margin-top:10px" v-if='avatar'>
        <el-image
          style="width: 150px; height: 75px;border-radius: 5px;"
          :src="avatar">
        </el-image>
        <p style="color:#fff;font-size:16px;">{{areaName}}</p>
      </div> -->
      <sidebar-item v-for="(route,index) in routes" :key="index" :item="route" :base-path="$route.path"/>
    </el-menu>
  </el-scrollbar>
</div>
</template>

<script>
  import {mapGetters} from 'vuex'
  import SidebarItem from './SidebarItem'

  export default {
    components: {SidebarItem},
    computed: {
      ...mapGetters([
        'sidebar'
      ]),
      routes() {
        let rou = this.$store.getters.routers;
        return rou
        // console.log(this.$router.options.routes)
        // return this.$router.options.routes
      },
      isCollapse() {
        return !this.sidebar.opened
      },
      avatar(){
        return this.$store.getters.avatar
      },
      areaName(){
        return this.$store.getters.areaName
      },
      clientHeight(){
        return document.documentElement.clientHeight
      }
    }
  }
</script>
<style scoped>
</style>
