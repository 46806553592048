import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import app from './modules/app'
import getters from './getters'
import tagsView from './modules/tagsView'
import carrier from './modules/bas/carrier'
import guestShop from './modules/bas/guestShop'
import area from './modules/bas/area'
import date from './modules/date'
import sku from './modules/bas/sku'
import basRoute from './modules/bas/basRoute'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters,
  mutations: {
  },
  actions: {
  },
  modules: {
    date,
    app,
    user,
    tagsView,
    carrier,
    guestShop,
    area,
    sku,
    basRoute
  }
})
