import axios from 'axios'
import { baseURL } from '@/utils/config'
import store from '../store'
import { getToken } from '@/utils/auth'
import { Message, MessageBox } from 'element-ui'

// let baseURL = ''
// if (process.env.NODE_ENV === 'production') {
//   if (process.env.VUE_APP_FLAG === 'test') {
//     baseURL = test
//   } else {
//     baseURL = prod
//   }
// } else {
//   baseURL = dev
// }

// 创建axios实例
const service = axios.create({
  baseURL: baseURL, // api 的 base_url
  // baseURL: 'http://wms-service.fuqueyun.com/wms-web', // api 的 base_url
  timeout: 120000, // 请求超时时间：120秒，两分钟
})
// request拦截器
service.interceptors.request.use(
  config => {
    // console.log('--------error +++ token----------------')
    if (store.getters.token) {
      // console.log(store.getters.token)
      // config.headers['Content-Type'] = 'application/json'
      config.headers['wmsToken'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
      config.headers['client'] = 'supplier' // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    console.log(config)
    config.baseURL = config.baseURL.replace("https:", location.protocol)
    config.url = config.url.replace("https:", location.protocol)
    return config
  },
  error => {
    // Do something with request error
    console.log("request拦截器错误:" + error) // for debug
    Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  response => {
    /**
     * code为非20000是抛错 可结合自己业务进行修改
     */
    const res = response.data
    // debugger
    if (!res.code && response.config.responseType == "blob") {// 是否为导出
      if (response.status == 200) {
        return response.data
      } else {
        let utf8decoder = new TextDecoder();
        let msg = JSON.parse(utf8decoder.decode(response.data))
        console.log(msg)
        Message({
          message: msg.msg,
          showClose:true,
          type: 'error',
          duration: 3 * 1000
        })
        return Promise.reject(msg)
      }
    } else if (!res.code && response.config.responseType == "arraybuffer") {// 是否为导出
      if (response.status == 200) {
        return response.data
      } else {
        let utf8decoder = new TextDecoder();
        let msg = JSON.parse(utf8decoder.decode(response.data))
        Message({
          message: msg.msg,
          showClose:true,
          type: 'error',
          duration: 3 * 1000
        })
        return Promise.reject(msg)
      }
    } else if (response.request.responseURL.indexOf('upload.qiniup.com') > -1) {
      return response.data
    } else if (res.code !== '000' && res.code !== '001') {
      Message({
        message: res.msg,
        showClose:true,
        type: 'info',
        duration: 3 * 1000
      })

      // 50008:非法的token; 50012:其他客户端登录了;  50014:Token 过期了;
      if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
        MessageBox.confirm(
          '你已被登出，可以取消继续留在该页面，或者重新登录',
          '确定登出',
          {
            confirmButtonText: '重新登录',
            cancelButtonText: '取消',
            type: 'warning'
          }
        ).then(() => {
          store.dispatch('FedLogOut').then(() => {
            location.reload() // 为了重新实例化vue-router对象 避免bug
          })
        })
      }
      return Promise.reject(response.data)
    } else {
      // console.log("response.data")
      return response.data
    }
  },
  error => {
    if (error.response != null && error.response.status == 401){/*未授权，跳登陆*/
      MessageBox.confirm(
        error.response.data,
        '提示',
        {
          showCancelButton:false,
          showClose:false,
          closeOnClickModal:false,
          confirmButtonText: '跳转登录页面',
          type: 'warning'
        }
      ).then(() => {
        store.dispatch('FedLogOut').then(() => {
          location.reload() // 为了重新实例化vue-router对象 避免bug
        })
      })
    }else if (error.response != null && error.response.status == 500){ //服务端返回异常
      Message({
        message: error.response.data,
        showClose:true,
        type: 'error',
        duration: 3 * 1000
      })
    }else{
      Message({
        message: `服务更新中，请稍后再试`,
        type: 'error',
        showClose:true,
        duration: 5 * 1000
      })
    }
    return Promise.reject(error)
  }
)
export function post(url, params,config) {
  return new Promise((resolve, reject) => {
    service
      .post(url, params,config)
      .then(
      response => {
        resolve(response);
      },
      err => {
          reject(err);
      }
      )
      .catch(error => {
      reject(error);
      });
  });
}
export function get(url, data, config) {
  let str = ''
	if (data) {
		str = '?'
		for(let x in data) {
			str += x + '=' + data[x] + '&'
		}
	}
  return new Promise((resolve, reject) => {
    service
      .get(url + str.substr(0, str.length - 1), config)
      .then(
        response => {
          resolve(response);
        },
        err => {
          reject(err);
        }
      )
      .catch(error => {
        reject(error);
      });
  });
}
export default service

