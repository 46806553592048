import store from '@/store'

const tagsView = {
  state: {
    visitedViews: [],
    cachedViews: [],
    historyViews: [],
  },
  mutations: {
    ADD_VISITED_VIEW: (state, view) => {
      if (state.visitedViews.length > 0 && state.visitedViews.some(v => v.path === view.path)) return
      state.visitedViews.push(
        Object.assign({}, view, {
          title: view.meta.title || 'no-name'
        })
      )
      state.historyViews.push(Object.assign({}, view, {
        title: view.meta.title || 'no-name'
      }));
    },
    ADD_CACHED_VIEW: (state, view) => {
      if (state.cachedViews.length > 0 && state.cachedViews.includes(view.name)) {
        return;
      }
      if (!view.meta.noCache) {
        state.cachedViews.push(view.name)
      }
    },
    DEL_VISITED_VIEW: (state, view) => {
      if (state.visitedViews.length === 0) {
        return
      }

      for (const [i, v] of state.visitedViews.entries()) {
        if (v.path === view.path) {
          state.visitedViews.splice(i, 1)
          break
        }
      }
    },
    DEL_CACHED_VIEW: (state, view) => {
      for (const i of state.cachedViews) {
        if (i === view.name) {
          const index = state.cachedViews.indexOf(i)
          state.cachedViews.splice(index, 1)
          break
        }
      }
    },
    DEL_HISTORY_VIEW: (state, view) => {
      if (state.historyViews.length === 0) {
        return
      }

      for (const [i, v] of state.historyViews.entries()) {
        if (v.path === view.path) {
          state.historyViews.splice(i, 1)
          break
        }
      }
    },
    DEL_OTHERS_VISITED_VIEWS: (state, view) => {
      for (const [i, v] of state.visitedViews.entries()) {
        if (v.path === view.path) {
          state.visitedViews = state.visitedViews.slice(i, i + 1)
          break
        }
      }
    },
    DEL_OTHERS_CACHED_VIEWS: (state, view) => {
      for (const i of state.cachedViews) {
        if (i === view.name) {
          const index = state.cachedViews.indexOf(i)
          state.cachedViews = state.cachedViews.slice(index, index + 1)
          break
        }
      }
    },

    DEL_ALL_VISITED_VIEWS: state => {
      state.visitedViews = []
    },
    DEL_ALL_CACHED_VIEWS: state => {
      state.cachedViews = []
    },
    DEL_ALL_HISTORY_VIEWS: state => {
      state.historyViews = []
    },
    UPDATE_VISITED_VIEW: (state, view) => {
      if (state.visitedViews.length === 0) {
        return
      }
      for (let v of state.visitedViews) {
        if (v.path === view.path) {
          v = Object.assign(v, view)
          break
        }
      }
    },
    UPDATE_TITLE: (state, i) => {
      state.visitedViews[i] = Object.assign(state.visitedViews[i], view)
    }
  },
  actions: {
    addView({dispatch}, view) {
      dispatch('addVisitedView', view)
      dispatch('addCachedView', view)
    },
    addVisitedView({commit}, view) {
      commit('ADD_VISITED_VIEW', view)
    },
    addCachedView({commit}, view) {
      commit('ADD_CACHED_VIEW', view)
    },
    delView({dispatch, state}, view) {
      return new Promise(resolve => {
        dispatch('delVisitedView', view)
        dispatch('delCachedView', view)
        dispatch('delHistoryView', view)
        resolve({
          visitedViews: [...state.visitedViews],
          cachedViews: [...state.cachedViews],
          historyViews: [...state.historyViews]
        })
      })
    },
    /**
     * 任何界面调用此方法都可关闭当前路由
     * @param dispatch
     * @param state
     * @param that       当前界面的this
     */
    delThisView({dispatch, state}, that) {
      let view = that.$router.history.current;
      dispatch('delVisitedView', view)
      dispatch('delCachedView', view)
      const latestView = state.visitedViews.slice(-1)[0]
      if (latestView) {
        that.$router.push(latestView)
      } else {
        that.$router.push(store.state.user.alias ? `/${store.state.user.alias}/home` : `/home`)
      }
    },
    toBefore({dispatch, state}, that) {
      let endView = state.historyViews.pop();
      state.historyViews.unshift(endView);
      const latestView = state.historyViews.slice(-1)[0]
      if (latestView) {
        that.$router.push(latestView)
      } else {
        this.$router.push(store.state.user.alias ? `/${store.state.user.alias}/home` : `/home`)
      }
    },
    closeView({dispatch, state}, that) {
      let view = that.$router.history.current;
      dispatch('delVisitedView', view)
      dispatch('delCachedView', view)
    },
    updateTitle({commit, state}, obj) {
      let view = obj.view.$router.history.current;
      if (state.visitedViews.length === 0) {
        return
      }
      for (const [i, v] of state.visitedViews.entries()) {
        if (v.path === view.path) {
          v.meta.title = obj.title
          break
        }
      }
    },
    delVisitedView({commit, state}, view) {
      return new Promise(resolve => {
        commit('DEL_VISITED_VIEW', view)
        resolve([...state.visitedViews])
      })
    },
    delCachedView({commit, state}, view) {
      return new Promise(resolve => {
        commit('DEL_CACHED_VIEW', view)
        resolve([...state.cachedViews])
      })
    },
    delHistoryView({commit, state}, view) {
      return new Promise(resolve => {
        commit('DEL_HISTORY_VIEW', view)
        resolve([...state.historyViews])
      })
    },
    delOthersViews({dispatch, state}, view) {
      return new Promise(resolve => {
        dispatch('delOthersVisitedViews', view)
        dispatch('delOthersCachedViews', view)
        resolve({
          visitedViews: [...state.visitedViews],
          cachedViews: [...state.cachedViews]
        })
      })
    },
    delOthersVisitedViews({commit, state}, view) {
      return new Promise(resolve => {
        commit('DEL_OTHERS_VISITED_VIEWS', view)
        resolve([...state.visitedViews])
      })
    },
    delOthersCachedViews({commit, state}, view) {
      return new Promise(resolve => {
        commit('DEL_OTHERS_CACHED_VIEWS', view)
        resolve([...state.cachedViews])
      })
    },
    delAllViews({dispatch, state}, view) {
      return new Promise(resolve => {
        dispatch('delAllVisitedViews', view)
        dispatch('delAllCachedViews', view)
        dispatch('delAllHistoryViews', view)
        resolve({
          visitedViews: [...state.visitedViews],
          cachedViews: [...state.cachedViews],
          historyViews: [...state.historyViews],
        })
      })
    },
    delAllVisitedViews({commit, state}) {
      return new Promise(resolve => {
        commit('DEL_ALL_VISITED_VIEWS')
        resolve([...state.visitedViews])
      })
    },
    delAllCachedViews({commit, state}) {
      return new Promise(resolve => {
        commit('DEL_ALL_CACHED_VIEWS')
        resolve([...state.cachedViews])
      })
    },
    delAllHistoryViews({commit, state}) {
      return new Promise(resolve => {
        commit('DEL_ALL_HISTORY_VIEWS')
        resolve([...state.historyViews])
      })
    },
    updateVisitedView({commit}, view) {
      commit('UPDATE_VISITED_VIEW', view)
    }
  }
}

export default tagsView
