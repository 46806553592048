<template>
  <wms-form-item v-if="span == 0"
    ref='formLable'
    :prop='prop'
    :label='label'
    :label-width='labelWidth'
    :required='required'
    :rules='rules'
    :error='error'
    :show-message='showMessage'
    :inline-message='inlineMessage'
    :size='formItemSize'
  >
    <el-select
      style="width: 100%"
      ref='wmsSelect'
      @change='selectChange'
      @visible-change='selectVisibleChange'
      @remove-tag='selectRemoveTag'
      @clear='selectClear'
      @blur='selectBlur'
      @focus='selectFocus'
      :value='value'
      @input="val => $emit('input', val)"
      :multiple='multiple'
      :disabled='disabled'
      :value-key='valueKey'
      :size='size'
      :clearable='clearable'
      :collapse-tags='collapseTags'
      :multiple-limit='multipleLimit'
      :name='name'
      :autocomplete='autocomplete'
      :placeholder='placeholder'
      :filterable='filterable'
      :allow-create='allowCreate'
      :filter-method='batchFilterable ? batchFilterableMethod : filterMethod'
      :remote='remote'
      :remote-method='remoteMethod'
      :loading='loading'
      :loading-text='loadingText'
      :no-match-text='noMatchText'
      :no-data-text='noDataText'
      :popper-class='popperClass'
      :reserve-keyword='reserveKeyword'
      :default-first-option='defaultFirstOption'
      :popper-append-toBody='popperAppendToBody'
      :automatic-dropdown='automaticDropdown'
    >
      <template slot='prefix'>
        <slot name='prefix'></slot>
      </template>
      <template slot='empty'>
        <slot name="empty"></slot>
      </template>
      <el-option v-for="(item, index) in defaultOptionList" :disabled="typeof optionDisabled == 'boolean' ? optionDisabled : optionDisabled[index]" :key="item.id || index" :value="item[optionKey]" :label='item[optionLabel]'>
        <slot name='optionScope' :item='item'></slot>
      </el-option>
      <slot></slot>
    </el-select>
  </wms-form-item>
  <el-col v-else :span="span">
    <wms-form-item
      ref='formLable'
      :prop='prop'
      :label='label'
      :label-width='labelWidth'
      :required='required'
      :rules='rules'
      :error='error'
      :show-message='showMessage'
      :inline-message='inlineMessage'
      :size='formItemSize'
    >
      <el-select
        style="width: 100%"
        ref='wmsSelect'
        @change='selectChange'
        @visible-change='selectVisibleChange'
        @remove-tag='selectRemoveTag'
        @clear='selectClear'
        @blur='selectBlur'
        @focus='selectFocus'
        :value='value'
        @input='val => $emit("input", val)'
        :multiple='multiple'
        :disabled='disabled'
        :value-key='valueKey'
        :size='size'
        :clearable='clearable'
        :collapse-tags='collapseTags'
        :multiple-limit='multipleLimit'
        :name='name'
        :autocomplete='autocomplete'
        :placeholder='placeholder'
        :filterable='filterable'
        :allow-create='allowCreate'
        :filter-method='batchFilterable ? batchFilterableMethod : filterMethod'
        :remote='remote'
        :remote-method='remoteMethod'
        :loading='loading'
        :loading-text='loadingText'
        :no-match-text='noMatchText'
        :no-data-text='noDataText'
        :popper-class='popperClass'
        :reserve-keyword='reserveKeyword'
        :default-first-option='defaultFirstOption'
        :popper-append-toBody='popperAppendToBody'
        :automatic-dropdown='automaticDropdown'
      >
        <template slot='prefix'>
          <slot name='prefix'></slot>
        </template>
        <template slot='empty'>
          <slot name="empty"></slot>
        </template>
        <el-option v-for="(item, index) in defaultOptionList" :disabled="typeof optionDisabled == 'boolean' ? optionDisabled : optionDisabled[index]" :key="item.id || index" :value="item[optionKey]" :label='item[optionLabel]'>
          <slot name='optionScope' :item='item'></slot>
        </el-option>
        <slot></slot>
      </el-select>
    </wms-form-item>
  </el-col>
</template>

<script>
export default {
  props: {
    value: {// 绑定值
      required: true
    },
    span: {// 当不为0时代表使用栅格
      type: Number,
      default: 0
    },
    // form-item
    prop: {// 使用表单方法时必填  传入Form组件的model中的字段
      type: String
    },
    label: {// 标签文本
      type: String
    },
    labelWidth: {// 表单域标签的的宽度，例如 '50px'。支持 auto。
      type: String
    },
    required: {// 是否必填，如不设置，则会根据校验规则自动生成
      type: Boolean,
      default: false
    },
    rules: {// 表单验证规则
      type: [Object, Array]
    },
    error: {// 表单域验证错误信息, 设置该值会使表单验证状态变为error，并显示该错误信息
      type: String
    },
    showMessage: {// 是否显示校验错误信息
      type: Boolean,
      default: true
    },
    inlineMessage: {// 以行内形式展示校验信息
      type: Boolean,
      default: false
    },
    formItemSize: {// 用于控制该表单域下组件的尺寸	medium / small / mini
      type: String
    },
    // select
    multiple: {// 是否多选
      type: Boolean,
      default: false
    },
    disabled: {// 是否禁用
      type: Boolean,
      default: false
    },
    valueKey: {// 作为 value 唯一标识的键名，绑定值为对象类型时必填
      type: String,
      default: 'value'
    },
    size: {// 输入框尺寸	 medium/small/mini
      type: String
    },
    clearable: {// 是否可以清空选项
      type: Boolean,
      default: true
    },
    collapseTags: {// 多选时是否将选中值按文字的形式展示
      type: Boolean,
      default: false
    },
    multipleLimit: {// 多选时用户最多可以选择的项目数，为 0 则不限制
      type: Number,
      default: 0
    },
    name: {// select input 的 name 属性
      type: String
    },
    autocomplete: {// select input 的 autocomplete 属性
      type: String,
      default: 'off'
    },
    placeholder: {// 占位符
      type: String,
      default: '请选择'
    },
    filterable: {// 是否可搜索
      type: Boolean,
      default: false
    },
    allowCreate: {// 是否允许用户创建新条目，需配合 filterable 使用
      type: Boolean,
      default: false
    },
    batchFilterable: {// 是否多字段筛选
      type: Boolean,
      default: false
    },
    batchFilterKeys: {
      type: Array
    },
    filterMethod: {// 自定义搜索方法
      type: Function
    },
    remote: {// 是否为远程搜索
      type: Boolean,
      default: false
    },
    remoteMethod: {// 远程搜索方法
      type: Function
    },
    loading: {// 是否正在从远程获取数据
      type: Boolean,
      default: false
    },
    loadingText: {// 远程加载时显示的文字
      type: String,
      default: '加载中'
    },
    noMatchText: {// 搜索条件无匹配时显示的文字，也可以使用slot="empty"设置
      type: String,
      default: '无匹配数据'
    },
    noDataText: {// 选项为空时显示的文字，也可以使用slot="empty"设置
      type: String,
      default: '无数据'
    },
    popperClass: {// Select 下拉框的类名
      type: String
    },
    reserveKeyword: {// 多选且可搜索时，是否在选中一个选项后保留当前的搜索关键词
      type: Boolean,
      default: false
    },
    defaultFirstOption: {// 在输入框按下回车，选择第一个匹配项。需配合 filterable 或 remote 使用
      type: Boolean,
      default: false
    },
    popperAppendToBody: {// 是否将弹出框插入至 body 元素。在弹出框的定位出现问题时，可将该属性设置为 false
      type: Boolean,
      default: true
    },
    automaticDropdown: {// 对于不可搜索的 Select，是否在输入框获得焦点后自动弹出选项菜单
      type: Boolean,
      default: false
    },
    optionKey: {// option 值
      type: String,
      default: 'id'
    },
    optionLabel: {// option label
      type: String,
      default: 'label'
    },
    optionDisabled: {// option是否禁用
      type: [Boolean, Array],
      default: function () {
        return false
      }
    },
    optionList: {// option集合
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      defaultOptionList: []
    }
  },
  created() {
    this.defaultOptionList = this.optionList
  },
  watch: {
    optionList(val) {
      this.defaultOptionList = val
    }
  },
  methods: {
    batchFilterableMethod(val) {
       this.defaultOptionList = this.optionList.filter(item => {
        let flag = true
        if (this.batchFilterKeys) {
          flag = false
          this.batchFilterKeys.forEach(keyItem => {
            if(!item[keyItem]) {
              return
            }
            if (item[keyItem].toLowerCase().indexOf(val.toLowerCase()) > -1) {
              flag = true
            }
          })
        }
        return flag
      })
    },
    selectChange(val) {
      this.$emit('change', val)
    },
    selectVisibleChange(val) {
      this.$emit('visible-change', val)
    },
    selectRemoveTag(val) {
      this.$emit('remove-tag', val)
    },
    selectClear() {
      this.$emit('clear')
    },
    selectBlur(val) {
      this.$emit('blur', val)
    },
    selectFocus(val) {
      // this.defaultOptionList = this.optionList
      this.$emit('focus', val)
    },
    focus() {
      this.$refs.wmsSelect.focus()
      this.$refs.wmsSelect.visible = true
    },
    blur() {
      this.$refs.wmsSelect.blur()
    }
  }
}
</script>

<style>

</style>
