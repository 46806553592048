<template>
  <div class="wmsContainer">
    <div ref='header' class="wmsContainerHeader">
      <slot name="header"></slot>
    </div>
    <div class="wmsContainerMainView" :class="haveShadow?'wmsContainerMain':''">
      <div class="wm" :style="{height: clientHeight + 'px'}">
        <slot name="main" :clientHeight="clientHeight"></slot>
        <slot></slot>
      </div>
      <div ref='footer'>
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    offsetHeight: {
      type: Number,
      default: 50
    },
    haveShadow: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      height: 0,
      time: false
    }
  },
  mounted () {
    this.setClientHeight()
    this.windowResize()
  },
  computed: {
    clientHeight() {
      return this.height
    }
  },
  methods: {
    setClientHeight() {
      if (this.time) {
        return
      }
      this.time = true
      setTimeout(() => {
        // let haveShadowlength = this.haveShadow ? 5 + 2 : 0
        let height = window.innerHeight - this.$refs.header.clientHeight - this.offsetHeight - this.$refs.footer.clientHeight - 12
        if (height != this.height) {
          this.height = height
        }
        this.time = false
      }, 300);
    },
    windowResize() {
      window.addEventListener('resize', () => {
        this.setClientHeight()
      })
    }
  }
}
</script>
<style scoped>
  .wmsContainer {
    padding: 5px 5px 0;
  }
  .wmsContainerHeader {
    padding-bottom: 8px;
  }
  .wmsContainerMainView {
    padding: 5px 10px 0 10px;
    border: 1px solid #ebeef5;
  }
  .wmsContainerMain {
    box-shadow: 0 2px 12px 0 rgba(0 , 0, 0, .1)
  }
</style>
<style>

  .wmsContainerHeader .el-card__body {
    padding: 2px 10px 0 10px
  }
</style>
