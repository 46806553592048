<template>
  <el-form-item
    ref='formLable'
    :prop='prop'
    :label='label'
    :label-width='labelWidth'
    :required='required'
    :rules='rules'
    :error='error'
    :show-message='showMessage'
    :inline-message='inlineMessage'
    :size='size'
    >
    <slot></slot>
  </el-form-item>
</template>

<script>
export default {
  name: 'wmsFormItem',
  props: {
    prop: {// 使用表单方法时必填  传入Form组件的model中的字段
      type: String
    },
    label: {// 标签文本
      type: String
    },
    labelWidth: {// 表单域标签的的宽度，例如 '50px'。支持 auto。
      type: String
    },
    required: {// 是否必填，如不设置，则会根据校验规则自动生成
      type: Boolean,
      default: false
    },
    rules: {// 表单验证规则
      type: [Object, Array]
    },
    error: {// 表单域验证错误信息, 设置该值会使表单验证状态变为error，并显示该错误信息
      type: String
    },
    showMessage: {// 是否显示校验错误信息
      type: Boolean,
      default: true
    },
    inlineMessage: {// 以行内形式展示校验信息
      type: Boolean,
      default: false
    },
    size: {// 用于控制该表单域下组件的尺寸	medium / small / mini
      type: String
    }
  },
  methods: {
    resetField() {
      this.$refs.formLable.resetField()
    },
    clearValidate() {
      this.$refs.formLable.clearValidate()
    }
  }
}
</script>

<style>

</style>
