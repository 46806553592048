import { findCarriers, getAddress } from '@/api/bas/carrier'

const carrier = {
  state: {
    carrier: [],
    provinceList: []
  },
  actions: {
    carriers({commit, state}) {
      return new Promise((resolve, reject) => {
        if (state.carrier == null || state.carrier.length === 0) {
          findCarriers().then(res => {
            commit('SET_CARRIER', res.data)
            resolve(state.carrier);
          }).catch(e => {
            reject(e)
          })
        } else {
          resolve(state.carrier)
        }
      })
    },
    resetCarrier({commit, state}) {
      return new Promise((resolve, reject) => {
        findCarriers().then(res => {
          commit('SET_CARRIER', res.data)
        }).catch(e => {
          reject(e)
        })
      })
    },
    getProvinceList({commit, state}) {
      if (state.carrier == null || state.carrier.length === 0) {
        getAddress().then(res => {
          commit('setProvinceList', res.data)
        }).catch(e => {
          commit('setProvinceList', [])
        })
      }
    },
    resetProvinceList({commit, state}) {
      getAddress().then(res => {
        commit('setProvinceList', res.data)
      }).catch(e => {
        commit('setProvinceList', [])
      })
    }
  },
  mutations: {
    SET_CARRIER: (state, data) => {
      state.carrier = data
    },
    setProvinceList: (state, data) => {
      state.provinceList = data
    }
  }
}

export default carrier
