<template>
  <div>
    <el-row>
      <el-col :span='17' style="height: 50px">
        <el-menu class="navbar" mode="horizontal">
          <hamburger :toggle-click="toggleSideBar" :is-active="sidebar.opened" class="hamburger-container"/>
          <!--<breadcrumb />-->
          <tags-view/>
        </el-menu>
      </el-col>
      <el-col class="avatar-container" :span="7">
        <span style="font-size: 14px;color: #a1a3a9;">{{ supplierName }} </span> 
        <i style="font-size: 14px; cursor: pointer; vertical-align: bottom" @click="switchWarehouse" class="el-my-icon-switch"></i>
        <el-dropdown @command="handleCommand" trigger="click">
          <div class="avatar-wrapper">
            <span 
              class="textOverFlow" 
              style="font-size:14px;max-width: 152px" 
              v-if="areaName!=null && areaName!=''">
              {{areaName}}
            </span>
            <span style="vertical-align: bottom">：</span>
            <span class="textOverFlow" style="font-size:14px;max-width: 68px">{{name}}</span>
            <img height="40px" width="40px" style="border-radius: 50%" :src="headImg ? headImg : defaultImg" class="user-avatar">
            <i class="el-icon-caret-bottom" style="vertical-align: bottom"/>
          </div>
          <el-dropdown-menu slot="dropdown" class="user-dropdown">
            <el-dropdown-item divided command="setImg">
              <span>更换头像</span>
            </el-dropdown-item>
            <el-dropdown-item divided command="alertPwd">
              <span>修改密码</span>
            </el-dropdown-item>
            <el-dropdown-item divided command="logout">
              <span style="display:block;">退出登录</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-col>

    </el-row>
    <wms-dialog 
      title='切换仓库'
      :dialogVisible.sync='warehouseFormVisible'
      @dialogClose='dialogClose'
      width='480px'
      haveFooter
    >
      <el-form style="width: 300px; margin: 0 auto" :rules='rules' :model="warehouseForm" label-width='80px' ref='warehouseForm' @submit.native.prevent>
        <wms-select
          label='选择仓库'
          prop='areaCode'
          filterable
          v-model="warehouseForm.areaCode"
          :optionList='areaCodeList'
          optionKey='areaCode'
          optionLabel='areaName'
        ></wms-select>
      </el-form>
      <div slot='dialogFooter' style="text-align: center">
        <el-button @click="setWarehouse" type="primary" plain>确定</el-button>
        <el-button @click="warehouseFormVisible = false" type="info" plain>取消</el-button>
      </div>
    </wms-dialog>
    <wms-dialog 
      title='修改头像'
      :dialogVisible.sync='setImgDialogVisible'
      width='480px'
      haveFooter>
      <div v-if="!updateImgForm.headImg" style="text-align: center">
        <el-upload
          ref='uploadImg'
          action="https://upload.qiniup.com/"
          :http-request="uploadQiniu"
          :limit="1"
          accept="image/*"
          list-type="picture-card">
          <i class="el-icon-plus"></i>
        </el-upload>
      </div>
      <div v-if="updateImgForm.headImg" style="height: 148px; width: 148px; position: relative; text-align: center; margin: 0 auto">
        <span style="position: absolute; top: 2px; right: 2px; cursor: pointer" @click.stop="updateImgForm.headImg = null" class="el-icon-close deleteIcon"></span>
        <img :src="updateImgForm.headImg" alt="" style="height: 148px; width: 148px">
      </div>
      <div slot='dialogFooter' style="text-align: center">
        <el-button @click="setImgSubmit" type="primary" plain>确定</el-button>
        <el-button @click="setImgDialogVisible = false" type="info" plain>取消</el-button>
      </div>
    </wms-dialog>
    <el-dialog
      title="修改密码"
      :visible.sync="alertDialog"
      :close-on-click-modal="false"
      @close="cancel()"
      v-dialogDrag
      width="400px"
      center>
      <el-form :loading="loading" ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="旧密码" prop="oldPwd" class="width-400">
          <el-input v-model="form.oldPwd"
                    onkeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
                    type="text"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPwd1" class="width-400">
          <el-input v-model="form.newPwd1"
                    onkeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
                    type="text"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="newPwd2" class="width-400">
          <el-input v-model="form.newPwd2"
                    onkeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
                    type="text"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="alertPwd">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'
  import Breadcrumb from '@/components/Breadcrumb'
  import Hamburger from '@/components/Hamburger'
  import TagsView from '@/components/TagsView'
  import { alertPwd, getAreaCodeList, switchAreaCode, alertHeadImg } from "@/api/login";
  import { getToken } from '@/api/customer/importOrder'

  export default {
    data() {
      return {
        alertDialog: false,
        loading: false,
        form: {
          oldPwd: null,
          newPwd1: null,
          newPwd2: null,
        },
        rules: {
          oldPwd: [
            {required: true, message: `该输入项为必填项`, trigger: "blur"}, 
            {min: 5, message: `最小长度为${5}位`, trigger: 'blur'}
          ],
          newPwd1: [
            {required: true, message: `该输入项为必填项`, trigger: "blur"}, 
            {min: 5, message: `最小长度为${5}位`, trigger: 'blur'}
          ],
          newPwd2: [
            {validator: this.ckeckPwd, trigger: 'blur'}, 
            {required: true, message: `该输入项为必填项`, trigger: "blur"}, 
            {min: 5, message: `最小长度为${5}位`, trigger: 'blur'}
          ],
          areaCode: [
            {required: true, message: '该输入项为必填项'}
          ]
        },
        style: null,
        warehouseFormLoading: false,
        warehouseFormVisible: false,
        warehouseForm: {
          areaCode: ''
        },
        areaCodeList: [],
        setImgDialogVisible: false, 
        imgImportLoading: false,
        updateImgForm: {
          headImg: null
        },
        defaultImg: require('@/assets/images/header.jpg')
      }
    },
    components: {
      Breadcrumb,
      Hamburger,
      TagsView
    },
    computed: {
      ...mapGetters([
        'sidebar',
        'avatar',
        'name',
        'areaName',
        'areaCode',
        'alias'
      ]),
      ...mapState({
        headImg: state => state.user.headImg,
        showChangeArea: state => state.user.showChangeArea,
        supplierName:state => state.user.supplierName,
      })
    },
    watch: {
      showChangeArea(val) {
        if (val) {
          this.switchWarehouseNav()
        }
      }
    },
    methods: {
      handleCommand(command) {
        if (command === 'alertPwd') {
          this.alertDialog = true
        } else if (command === 'logout') {
          this.logout();
        } else if (command === 'setImg') {
          this.setImg()
        }
      },
      setImg() {
        this.setImgDialogVisible = true
        this.updateImgForm.headImg = null
      },
      uploadQiniu(f) {
        getToken()
        .then(res => {
          let time = new Date().getTime()
          let param = new FormData()
          param.append('file', f.file)
          param.append('token', res.data)
          param.append('key', time + '-' + f.file.name)
          this.$request.post('https://upload.qiniup.com/', param)
          .then(qiNiuRes => {
            if (!qiNiuRes.key) {
              console.log('上传失败')
            } else {
              this.updateImgForm.headImg = 'https://wms-file.fuqueyun.com/' + time + '-' + f.file.name
            }
          })
        })
      },
      setImgSubmit() {
        if (this.imgImportLoading) {
          return
        }
        this.imgImportLoading = true
        alertHeadImg(this.updateImgForm)
        .then(res => {
          this.imgImportLoading = false
          if (res.success) {
            this.setImgDialogVisible = false
            this.$store.dispatch('resetHeadImg', this.updateImgForm.headImg)
          } else {
            this.$bmlMessage.error(res.msg)
          }
        }).catch(err => {
          this.imgImportLoading = false
        })
      },
      toggleSideBar() {
        this.$store.dispatch('ToggleSideBar')
      },
      logout() {
        this.$store.dispatch('FedLogOut')
        this.$router.push({
          path: this.alias ? `/${this.alias}/login` : `/login`,
        })
        setTimeout(() => {
          location.reload() // 为了重新实例化vue-router对象 避免bug
        }, 300)
      },
      cancel() {
        this.alertDialog = false;
        this.$refs['form'].resetFields();
      },
      alertPwd() {
        this.$refs.form.validate(valid => {
          if (valid) {
            alertPwd(this.form)
              .then(res => {
                if (res.success) {
                  this.$bmlMessage.success({
                    message: res.msg,
                    showClose: true,
                  })
                  this.logout()
                } else {
                  this.$bmlMessage.error({
                    message: res.msg,
                    showClose: true,
                  })
                }
              })
              .catch(e => {
                console.log(e);
              })
          }
        });
      },
      ckeckPwd(rule, value, callback) {
        if (!value) {
          return new callback(new Error('该输入项为必填项'))
        }
        if (!this.form.newPwd1) {
          return new callback(new Error('新密码不能为空'))
        }
        if (this.form.newPwd1 !== this.form.newPwd2) {
          return new callback(new Error('两次密码不一致'))
        }
        return callback()
      },
      switchWarehouseNav() {// 导航栏需登录仓库
        getAreaCodeList({
          domain: this.alias
        }).then(res => {
          if (res.data.length == 0) {
            this.$confirm(`<div style='text-align: left'>
              您还未创建任何分仓，请先创建分仓后方可使用此功能。
              </div>`,
              '提示', {
              confirmButtonText: '去创建',
              cancelButtonText: '取消',
              dangerouslyUseHTMLString: true,
              center: true,
              customClass: 'cancalConfirm'
            }).then(item => {
              this.$router.push({
                path: this.alias ? `/${this.alias}/bas/areaDetail` : `/bas/areaDetail`,
                query: {
                  tmp: new Date().dateFtt("yyyyMMddhhmmss")
                }
              })
              this.$store.dispatch('invokeSetShowChangeArea', false)
            }).catch(err => {
              this.$store.dispatch('invokeSetShowChangeArea', false)
            })
          } else if (res.data.length == 1) {
            this.$confirm(`<div style='text-align: left'>
              您当前未登录任何仓库，需先登录仓库才能使用本页面功能。
              </div>`,
              '提示', {
              confirmButtonText: '去登录',
              cancelButtonText: '取消',
              dangerouslyUseHTMLString: true,
              center: true,
              customClass: 'cancalConfirm'
            }).then(item => {
              this.switchAreaCode({
                areaCode: res.data[0].areaCode
              })
              this.$store.dispatch('invokeSetShowChangeArea', false)
            }).catch(err => {
              this.$store.dispatch('invokeSetShowChangeArea', false)
            })
          } else {
            this.areaCodeList = res.data
            this.warehouseFormVisible = true
            this.$refs.warehouseForm ? this.$refs.warehouseForm.resetFields() : null
          }
        })
      },
      switchWarehouse() {
        getAreaCodeList({
          domain: this.alias
        }).then(res => {
          this.areaCodeList = res.data
          if (res.data.length > 1) {
            if (res.data.length == 2) {
              if (this.warehouseFormLoading) {
                return
              }
              let data = this.areaCodeList.filter(item => item.areaCode != this.areaCode)
              this.switchAreaCode({
                areaCode: data[0].areaCode
              })
            } else {
              this.warehouseFormVisible = true
              this.$refs.warehouseForm ? this.$refs.warehouseForm.resetFields() : null
            }
          } else {
            if (res.data.length == 1 && !this.areaCode) {
              this.switchAreaCode({
                areaCode: this.areaCodeList[0].areaCode
              })
            } else {
              this.$bmlMessage.warning('无其他仓库')
            }
          }
        })
      },
      dialogClose() {
        this.$store.dispatch('invokeSetShowChangeArea', false)
      },
      setWarehouse() {
        if (this.warehouseFormLoading) {
          return
        }
        this.$refs.warehouseForm.validate()
        .then(valid => {
          this.switchAreaCode(this.warehouseForm)
        }).catch(err => {
          console.log(err)
        })
      },
      switchAreaCode(data) {
        this.warehouseFormLoading = true
        switchAreaCode(data)
        .then(res => {
          this.warehouseFormLoading = false
          if (res.success) {
            this.$store.dispatch('invokerSetToken', (res.data.token))
            this.$store.dispatch('invokeSetAreaName', (res.data.areaName))
            this.$store.dispatch('invokeSetAreaCode', (res.data.areaCode))
            this.$store.dispatch('invokeSetAvatar', (res.data.avator))
            this.warehouseFormVisible = false
            this.$store.dispatch('delAllViews')
            this.$router.push({
              path: this.alias ? `/${this.alias}/home` : `/home`,
              query: {
                reservoir: res.data.hasReservoirAuth == 1 && res.data.hasReservoir === 0 ? 1 : 0
              }
            })
            setTimeout(() => {
              location.reload()
            })
          } else {
            this.$bmlMessage.error(res.msg)
          }
        })
      }
    }
  }
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  .navbar {
    height: 50px;
    line-height: 50px;
    border-radius: 0px !important;

    .hamburger-container {
      line-height: 58px;
      height: 50px;
      float: left;
      padding: 0 10px;
    }

    .screenfull {
      position: absolute;
      right: 90px;
      top: 16px;
      color: red;
    }

    
  }
  .avatar-container {
    height: 50px;
    border-bottom: 1px solid #e6e6e6;
    text-align: right;
    // height: 50px;
    // display: inline-block;
    // position: absolute;
    // right: 35px;
    // top: 0px;

    .avatar-wrapper {
      cursor: pointer;
      margin-top: 5px;
      position: relative;
      line-height: initial;

      .user-avatar {
        // width: 40px;
        // height: 40px;
        border-radius: 10px;
      }

      .el-icon-caret-bottom {
        // position: absolute;
        // right: -20px;
        // top: 25px;
        font-size: 12px;
      }
    }
  }
  .textOverFlow {
    display: inline-block;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    vertical-align: bottom;
  }
</style>

