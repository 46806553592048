<template>
  <wms-form-item v-if="span == 0"
    :prop='prop'
    :label='label'
    :label-width='labelWidth'
    :required='required'
    :rules='rules'
    :error='error'
    :show-message='showMessage'
    :inline-message='inlineMessage'
    :size='formItemSize'
  >
    <el-autocomplete
      style="width: 100%"
      ref='wmsAutocomplete'
      :placeholder='placeholder'
      :disabled='disabled'
      :value-key='valueKey'
      :value='value'
      @focus="focus"
      @input="val => $emit('input', val)"
      @change.native='autoComplateChange($event)'
      @select='autoComplateSelect'
      @clear="clear($event)"
      :debounce='debounce'
      :placement='placement'
      :fetch-suggestions='useSuggest?querySearch:fetchSuggestions'
      :popper-class='popperClass'
      :trigger-on-focus='triggerOnFocus'
      :name='name'
      
      :type='type'
      :select-when-unmatched='selectWhenUnmatched'
      :label='label'
      :prefix-icon='prefixIcon'
      :suffix-icon='suffixIcon'
      :hide-loading='hideLoading'
      :popper-append-to-body='popperAppendToBody'
      :highlight-first-item='highlightFirstItem'
      :clearable='clearable'
    >
      <template slot='prefix'>
        <slot name='prefix'></slot><!-- 输入框头部内容 -->
      </template>
      <template slot='suffix'>
        <slot name='suffix'></slot><!-- 输入框尾部内容 -->
      </template>
      <template slot='prepend'>
        <slot name='prepend'></slot><!-- 输入框前置内容 -->
      </template>
      <template slot='append'>
        <slot name='append'></slot><!-- 输入框后置内容 -->
      </template>
      <template v-if="slotScope" slot-scope="{ item }">
        <slot :option='item'></slot>
      </template>
    </el-autocomplete>
  </wms-form-item>
  <el-col v-else :span='span'>
    <wms-form-item
      :prop='prop'
      :label='label'
      :label-width='labelWidth'
      :required='required'
      :rules='rules'
      :error='error'
      :show-message='showMessage'
      :inline-message='inlineMessage'
      :size='formItemSize'
    >
      <el-autocomplete
        style="width: 100%"
        @focus="focus"
        @select='autoComplateSelect'
        @change.native='autoComplateChange($event)'
        @clear="clear($event)"
        :placeholder='placeholder'
        :disabled='disabled'
        :value-key='valueKey'
        :value='value'
        :type='type'
        ref='wmsAutocomplete'
        @input="val => $emit('input', val)"
        :debounce='debounce'
        :placement='placement'
        :fetch-suggestions='useSuggest?querySearch:fetchSuggestions'
        :popper-class='popperClass'
        :trigger-on-focus='triggerOnFocus'
        :name='name'
        :select-when-unmatched='selectWhenUnmatched'
        :label='label'
        :prefix-icon='prefixIcon'
        :suffix-icon='suffixIcon'
        :hide-loading='hideLoading'
        :popper-append-to-body='popperAppendToBody'
        :highlight-first-item='highlightFirstItem'
        :clearable='clearable'
      >
        <template slot='prefix'>
          <slot name='prefix'></slot><!-- 输入框头部内容 -->
        </template>
        <template slot='suffix'>
          <slot name='suffix'></slot><!-- 输入框尾部内容 -->
        </template>
        <template slot='prepend'>
          <slot name='prepend'></slot><!-- 输入框前置内容 -->
        </template>
        <template slot='append'>
          <slot name='append'></slot><!-- 输入框后置内容 -->
        </template>
        <template v-if="haveSlotScope" slot-scope="{ item }">
          <slot :option='item'></slot>
        </template>
      </el-autocomplete>
    </wms-form-item>
  </el-col>
</template>

<script>

export default {
  props: {
    value: {// 绑定值
      required: true
    },
    span: {// 当不为0时代表使用栅格
      type: Number,
      default: 0
    },
    // form-item
    prop: {// 使用表单方法时必填  传入Form组件的model中的字段
      type: String
    },
    label: {// 标签文本
      type: String
    },
    labelWidth: {// 表单域标签的的宽度，例如 '50px'。支持 auto。
      type: String
    },
    required: {// 是否必填，如不设置，则会根据校验规则自动生成
      type: Boolean,
      default: false
    },
    rules: {// 表单验证规则
      type: [Object, Array]
    },
    error: {// 表单域验证错误信息, 设置该值会使表单验证状态变为error，并显示该错误信息
      type: String
    },
    showMessage: {// 是否显示校验错误信息
      type: Boolean,
      default: true
    },
    inlineMessage: {// 以行内形式展示校验信息
      type: Boolean,
      default: false
    },
    formItemSize: {// 用于控制该表单域下组件的尺寸	medium / small / mini
      type: String
    },
    // autoComplate
    placeholder: {// 输入框占位文本
      type: String
    },
    disabled: {// 禁用
      type: Boolean,
      default: false
    },
    valueKey: {// 输入建议对象中用于显示的键名
      type: String,
      default: 'value'
    },
    valueKeys: {// 输入建议对象中用于显示的键名
      type: Array,
      default: () => []
    },
    value: {// 必填值，输入绑定值
      type: String,
      required: true
    },
    type: {// 类型
      type: String,
      default: 'text'
    },
    debounce: {// 获取输入建议的去抖延时
      type: Number,
      default: 300
    },
    placement: {// 菜单弹出位置	  top / top-start / top-end / bottom / bottom-start / bottom-end
      type: String,
      default: 'bottom-start'
    },
    useSuggest: {
      type: Boolean,
      default: true
    },
    fetchSuggestions: {// 返回输入建议的方法，仅当你的输入建议数据 resolve 时，通过调用 callback(data:[]) 来返回它
      type: Function
    },
    popperClass: {// Autocomplete 下拉列表的类名
      type: String
    },
    triggerOnFocus: {// 是否在输入框 focus 时显示建议列表
      type: Boolean,
      default: true
    },
    name: {// 原生属性
      type: String
    },
    selectWhenUnmatched: {// 在输入没有任何匹配建议的情况下，按下回车是否触发 select 事件
      type: Boolean,
      default: false
    },
    label: {// 输入框关联的label文字
      type: String
    },
    prefixIcon: {// 输入框头部图标
      type: String
    },
    suffixIcon: {// 输入框尾部图标
      type: String
    },
    hideLoading: {// 是否隐藏远程加载时的加载图标
      type: Boolean,
      default: false
    },
    popperAppendToBody: {// 是否将下拉列表插入至 body 元素。在下拉列表的定位出现问题时，可将该属性设置为 false
      type: Boolean,
      default: true
    },
    highlightFirstItem: {// 是否默认突出显示远程搜索建议中的第一项
      type: Boolean,
      default: false
    },
    haveSlotScope: {// 使用自定义option
      type: Boolean,
      default: false
    },
    slotScope: {
      type: Boolean,
      default: false
    },
    optionList: {
      type: Array,
      default: () => []
    },
    clearable: {
      type: Boolean,
      default: true
    }

  },
  methods: {
    autoComplateSelect(val) {
      this.$emit('select', val)
    },
    autoComplateChange(e) {
      this.$emit('change', e.target.value)
    },
    blur() {
      this.$emit('blur', e.target.value)
    },
    clear(e) {
      this.$emit('clear', e)
    },
    focus($event,index) {
      this.$emit('focusInput', $event,index)
    },
    createFilter(queryString) {
      return (restaurant) => {
        if (this.valueKeys.length === 0) {
          return (restaurant[this.valueKey].toLowerCase().indexOf(queryString.toLowerCase()) === 0)
        } else {
          let flag = false
          this.valueKeys.forEach(item => {
            if (restaurant[item].toLowerCase().indexOf(queryString.toLowerCase()) > -1) {
              flag = true
            }
          })
          return flag
        }
      }
    },
    querySearch(queryString, cb) {
      var optionList = this.optionList
      console.log(queryString,'queryString')
      this.$refs.wmsAutocomplete.activated = true
      var results = queryString ? optionList.filter(this.createFilter(queryString)) : optionList
      // 调用 callback 返回建议列表的数据
      cb(results)
    }
  }

}
</script>

<style scoped>
  .my-autocomplete > li {
    line-height: normal !important;
    padding: 7px !important;
  }

  .my-autocomplete li .name {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .my-autocomplete li .addr {
    font-size: 12px;
    color: #b4b4b4;
  }

  .my-autocomplete li .highlighted .addr {
    color: #ddd;
  }
</style>
