import request from '@/utils/request'
import { post, get } from '@/utils/request'
import { baseExportURL } from '@/utils/config'

// 导入

export function queryErr(form) {
  return request({
    url: baseExportURL + `/import/queryErr`,
    method: 'post',
    data: form
  })
}
export function deleteImportFileInfo(form) {
  return request({
    url: `/wms-web/import/file/deleteImportFileInfo`,
    method: 'post',
    data: form
  })
}
export function getToken() {
  return request({
    url: baseExportURL + `/qiNiuTokenController/getToken`,
    method: 'post'
  })
}