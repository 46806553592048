<template>
  <el-table
    :data="data"
    ref='table'
    :height='height'
    :max-height='maxHeight'
    :border='border'
    :stripe='stripe'
    :size='size'
    :fit='fit'
    :showHeader='showHeader'
    :highlight-current-row='highlightCurrentRow'
    :currentRowKey='currentRowKey'
    :row-class-name='rowClassName'
    :row-style='rowStyle'
    :cell-style='cellStyle'
    :cell-class-name='cellClassName'
    :header-row-class-name='headerRowClassName'
    :header-cell-class-name='headerCellClassName'
    :header-cell-style='headerCellStyle'
    :row-key='rowKey'
    :empty-text='emptyText'
    :default-expand-all='defaultExpandAll'
    :expand-row-keys='expandRowKeys'
    :default-sort='defaultSort'
    :tooltip-effect='tooltipEffect'
    :show-summary='showSummary'
    :sum-text='sumText'
    :summary-method	='summaryMethod'
    :span-method='spanMethod'
    :select-on-indeterminate='selectOnIndeterminate'
    :indent='indent'
    :lazy='lazy'
    :load='load'
    :treeProps='treeProps'
    @select='select'
    @select-all='selectAll'
    @selection-change='selectionChange'
    @cell-mouse-enter='cellMouseEnter'
    @cell-mouse-leave='cellMouseLeave'
    @cell-click='cellClick'
    @cell-dblclick='cellDblclick'
    @row-click='rowClick'
    @row-contextmenu='rowContextmenu'
    @row-dblclick='rowDblclick'
    @header-click='headerClick'
    @header-contextmenu='headerContextmenu'
    @sort-change='sortChange'
    @filter-change='filterChange'
    @current-change='currentChange'
    @header-dragend='headerDragend'
    @expand-change='expandChange'
    >
    <el-table-column v-if="showSelect" type="selection" :selectable='selectable' width="50" align="center"></el-table-column>
    <el-table-column v-if="showIndex" label='序号' type="index" width="50" align="center"></el-table-column>
    <slot name="before"></slot>
    <el-table-column
      v-for="(item, index) in tableTitleList"
      :column-key="index.toString()"
      :renderHeader='renderHeader'
      :key="index"
      :label="item.columnValue"
      :show-overflow-tooltip='Boolean(item.showOverflowTooltip)'
      :prop='item.columnKey'
      :sortable='Boolean(item.sortable)'
      :min-width="item.columnWidth"
    >

      <template slot="header">
        <slot :name="`column_header_${item.columnKey}`" :row="item">
          {{item.columnValue}}
        </slot>
      </template>
      <template slot-scope="scope">
        <slot :name="`column_${item.columnKey}`" :row="scope.row">
          {{scope.row[item.columnKey]}}
        </slot>
      </template>
    </el-table-column>
    <slot></slot>
    <slot name='content'></slot>
  </el-table>
</template>
<script>
export default {
  props: {
    showIndex: {
      type: Boolean,
      default: true
    },
    showSelect: {
      type: Boolean,
      default: true
    },
    tableTitleList: {
      type: Array,
      default: () => {
        return []
      }
    },
    data: {
      type: Array,
      required: true
    },
    stripe: {
      type: Boolean,
      default: true
    },
    border: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: 'small'
    },
    height: {
      type: [String , Number],
    },
    maxHeight: {
      type: [String , Number],
    },
    fit: {
      type: Boolean,
      default: true
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    highlightCurrentRow: {
      type: Boolean,
      default: true
    },
    currentRowKey: {
      type: [String , Number]
    },
    rowClassName: {
      type: Function,
    },
    rowStyle: {
      type: Function
    },
    cellStyle: {
      type: Function
    },
    cellClassName: {
      type: Function
    },
    headerRowClassName: {
      type: Function
    },
    headerRowStyle: {
      type: Function
    },
    headerCellClassName: {
      type: Function
    },
    headerCellStyle: {
      type: Function,
      default()  {
        return {background:'#dddddd',color:'#666',}
      }
    },
    rowKey: {
      type: Function
    },
    emptyText: {
      type: String,
      default: '暂无数据'
    },
    defaultExpandAll: {
      type: Boolean,
      default: false
    },
    expandRowKeys: {
      type: Array
    },
    defaultSort: {
      type: Object,
    },
    tooltipEffect: {
      type: String,
    },
    showSummary: {
      type: Boolean,
      default: false
    },
    sumText: {
      type: String,
      default: '合计'
    },
    summaryMethod: {
      type: Function,
    },
    spanMethod: {
      type: Function
    },
    selectOnIndeterminate: {
      type: Boolean,
      default: true
    },
    indent: {
      type: Number,
      default: 16
    },
    lazy: {
      type: Boolean
    },
    load: {
      type: Function
    },
    treeProps: {
      type: Object
    },
    renderHeader: {
      type: Function
    },
    selectable: {
      type: Function
    },
  },
  data () {
    return {

    }
  },
  mounted () {
    let table = this.$refs.table.bodyWrapper
    table.addEventListener('scroll', () => {
      this.tableScroll()
    })
  },
  methods: {
    select(selection, row) {
      this.$emit('select',
        selection,
        row
      )
    },
    selectAll(selection) {
      this.$emit('select-all', selection)
    },
    selectionChange(selection) {
      this.$emit('selection-change', selection)
    },
    cellMouseEnter(row, column, cell, event) {
      this.$emit('cellMouse-enter', row, column, cell, event)
    },
    cellMouseLeave(row, column, cell, event) {
      this.$emit('cellMouseleave', row, column, cell, event)
    },
    cellClick(row, column, cell, event) {
      this.$emit('cell-click', row, column, cell, event)
    },
    cellDblclick(row, column, cell, event) {
      this.$emit('cell-dblclick', row, column, cell, event)
    },
    rowClick(row, column, event) {
      this.$emit('row-click', row, column, event)
    },
    rowContextmenu(row, column, event) {
      this.$emit('row-contextmenu', row, column, event)
    },
    rowDblclick(row, column, event) {
      this.$emit('row-dblclick', row, column, event)
    },
    headerClick(column, event) {
      this.$emit('header-click', column, event)
    },
    headerContextmenu(column, event) {
      this.$emit('header-contextmenu', column, event)
    },
    sortChange(event) {
      this.$emit('sort-change', event)
    },
    filterChange(filters) {
      this.$emit('filter-change', filters)
    },
    currentChange(currentRow, oldCurrentRow) {
      this.$emit('current-change', currentRow, oldCurrentRow)
    },
    headerDragend(newWidth, oldWidth, column, event) {
      this.$emit('header-dragend', newWidth, oldWidth, column, event)
    },
    expandChange(row, expanded) {
      this.$emit('expand-change', expanded)
    },
    tableScroll() {
      this.$emit('scroll')
    },
    clearSelection() {
      this.$refs.table.clearSelection()
    },
    toggleRowSelection(row, selected) {
      this.$refs.table.toggleRowSelection(row, selected)
    },
    toggleAllSelection() {
      this.$refs.table.toggleAllSelection()
    },
    toggleRowExpansion(row, expanded) {
      this.$refs.table.toggleRowExpansion(row, expanded)
    },
    setCurrentRow(row) {
      this.$refs.table.setCurrentRow()
    },
    clearSort() {
      this.$refs.table.clearSort()
    },
    clearFilter(columnKey) {
      this.$refs.table.clearFilter(columnKey)
    },
    doLayout() {
      this.$refs.table.doLayout()
    },
    sort(prop, order) {
      this.$refs.table.sort(prop, order)
    }
  }
}
</script>
