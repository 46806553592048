import Cookies from 'js-cookie'

const TokenKey = 'wmsSupplierToken'

// cookie:token 操作
export function getToken() {
  return getCookie(TokenKey);
}

export function setToken(token) {
  setCookie(TokenKey, token);
}

export function getCookie(key) {
  return Cookies.get(key);
}

export function setCookie(key, value) {
  return Cookies.set(key, value);
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}

export function removeCookie(key) {
  return Cookies.remove(key);
}
