// const dev = 'http://1.116.186.197:8666'
// const dev = 'https://wms-service.sfcchina.com.cn'
//11
const dev = 'https://newwms-service.sfcchina.com.cn'
const devExport = 'https://newwms-service.sfcchina.com.cn/wms-data-export'

const test = 'https://newwms-service.sfcchina.com.cn'
const testExport = 'https://newwms-service.sfcchina.com.cn/wms-data-export'

const prod = 'https://wms-service.laowanhui.com'
const prodExport = 'https://wms-service.laowanhui.com/wms-data-export'

let baseURL = ''
let baseExportURL = ''
if (process.env.NODE_ENV === 'production') {
  if (process.env.VUE_APP_FLAG === 'test') {
    baseURL = test
    baseExportURL = testExport
  } else {
    baseURL = prod
    baseExportURL = prodExport
  }
} else {
  baseURL = dev
  baseExportURL = devExport
}

export {
  baseURL,
  baseExportURL
}

