<template>
  <div class='wmsFooter' :style="style">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    align: {
      type: String,
      default: 'center'
    },
    flex: {
      type: Boolean,
      default: false
    },
    flexStyle: {
      type: Object
    },
    lineHeight: {
      type: String,
      default: '50px'
    }
  },
  computed: {
    style() {
      if (this.flex) {
        return {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          ...this.flexStyle,
          lineHeight: this.lineHeight,
        }
      } else {
        return {
          textAlign: this.align,
          lineHeight: this.lineHeight
        }
      }
    }
  }
}
</script>

<style scoped>
  .wmsFooter {
    /* height: 50px; */
    line-height: 50px;
  }
</style>
