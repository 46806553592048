import request from '@/utils/request'

//查询大区
export const getRegionLabelList = (data) => {
  return request({
    url: `/wms-web/basGuestShop/getRegionLabelList`,
    method: 'post',
    data
  })
}
//查门店
export const getShopRemote = (data) => {
  return request({
    url: `/wms-web/basGuestShop/select/list`,
    method: 'post',
    data
  })
}

//上游仓库查询
export const upstream = (data) => {
  return request({
    url: `/wms-web/area/upstream/search`,
    method: 'post',
    data
  })
}
