import request from '@/utils/request'
import {get, post } from '@/utils/request';

/**
 * 保存或更新
 * @param form
 */
export function saveOrUpdate(form) {
    return request({
        url: `/wms-web/bas/sku/saveOrUpdate`,
        method: 'post',
        data: form
    })
}

// export function findSkuBySkuAndCustomer(customer, sku) {
//   return request({
//     url: `/wms-web/bas/sku/${customer}/${sku}`,
//     method: 'get'
//   })
// }

export function findSkuBySkuAndCustomer(form) {
    return request({
        url: `/wms-web/bas/sku/`,
        method: 'post',
        data: form,
    })
}



export function findBySkuOrErpCode(form) {
    return request({
        url: `/wms-web/bas/sku/skuOrErpCode/`,
        method: 'post',
        data: form,
    })
}

export function findSkuByCustomer(customer) {
    return request({
        url: `/wms-web/bas/sku/${customer}`,
        method: 'get'
    })
}

// 批量导入sku
export function importSku(skus) {
    return request({
        url: `/wms-web/bas/sku/saveBatch`,
        method: 'post',
        data: skus
    })
}

// 查询sku
export function search(form, current, size) {
    return request({
        url: `/wms-web/bas/sku/pages/${current}/${size}`,
        method: 'post',
        data: form
    })
}

// 查询sku
export function sellSkus(form) {
    return request({
        url: `/wms-web/bas/sku/sellSkus`,
        method: 'post',
        data: form
    })
}

// 查询sku
export function getSku(form) {
    return request({
        url: `/wms-web/bas/sku/getSku`,
        method: 'post',
        data: form
    })
}
// 保存换发Sku
export function saveSendSkuUpdata(form) {
    return request({
        url: `/wms-web/bas/sku/change/save`,
        method: 'post',
        data: form,
    })
}
// 查看批号列表

// export function batchNumberList(form, current, size) {
//   return request({
//     url: `/wms-web/asn/batch/pages/${current}/${size}`,
//     method: 'post',
//     data:form
//   })
// }

// 手动添加批号信息

// export function saveHandSendSku(form) {
//   return request({
//     url: `/wms-web/asn/batch/save`,
//     method: 'post',
//     data: form,
//   })
// }

//获取附属品

// export function getAccessorySkuId(id) {
//   return request({
//     url: `/wms-web/basSkuAccessory/getAccessorySkuId/${id}`,
//     method: 'post'
//   })
// }
// 查询sku
export function getLocationByCust(data) {
    return request({
        url: `/wms-web/bas/sku/getLocationByCust`,
        method: 'post',
        data
    })
}

// 添加图片
export function saveSkuImage(data) {
    return request({
        url: `/wms-web/bas/sku/saveSkuImage`,
        method: 'post',
        data
    })
}

// 删除图片
export function deleteSkuImage(data) {
    return request({
        url: `/wms-web/bas/sku/deleteSkuImage`,
        method: 'post',
        data
    })
}
// 获取加工制造工艺
// 换到标品菜了
// export function getProcessDetail(data) {
//     return request({
//         url: `/wms-web/bas/sku/getProcessDetail`,
//         method: 'post',
//         data
//     })
// }
// 粗加工详情
export function getSplitDetail(data) {
    return request({
        url: `/wms-web/bas/sku/getSplitDetail`,
        method: 'post',
        data
    })
}
// 修改加工制造工艺配置
// export function saveOrUpdateByProcess(data) {
//     return request({
//         url: `/wms-web/bas/sku/saveOrUpdateByProcess`,
//         method: 'post',
//         data
//     })
// }
export function saveOrUpdateBySplit(data) {
    return request({
        url: `/wms-web/bas/sku/saveOrUpdateBySplit`,
        method: 'post',
        data
    })
}
// 修改加工制造工艺配置
export function getBatchNoteList(data) {
    return request({
        url: `/wms-web/bas/sku/getBatchNoteList`,
        method: 'post',
        data
    })
}


//波次 打印唯一码获取数据
export function printSku(data) {
    return post(`/wms-web/bas/sku/printSku`, data, { responseType: 'arraybuffer' })
}

// 修改加工制造工艺配置
export function modifySkuUserundefine(data) {
    return request({
        url: `/wms-web/bas/sku/modifySkuUserundefine`,
        method: 'post',
        data
    })
}

// 加工工艺模板配置新增、修改
export function saveSkuDefaultProcess(data) {
    return request({
        url: `/wms-web/bas/sku/saveSkuDefaultProcess`,
        method: 'post',
        data
    })
}

// 加工工艺模板配置下拉框
export function getProcessTemplateNameList(data) {
    return request({
        url: `/wms-web/bas/sku/getProcessTemplateNameList`,
        method: 'post',
        data
    })
}

// 加工工艺模板配置详情
export function getSkuDefaultProcessById(id) {
    return request({
        url: `/wms-web/bas/sku/getSkuDefaultProcessById/${id}`,
        method: 'post',
    })
}

// 四级分类
export function getSkuCategoryList(data) {
    return request({
        url: `/wms-web/skuCategory/getSkuCategoryList`,
        method: 'post',
        data
    })
}

// 新增四级分类
export function saveOrUpdataSkuCategory(data) {
    return request({
        url: `/wms-web/skuCategory/saveOrUpdataSkuCategory`,
        method: 'post',
        data
    })
}

// // 保存换发Sku
// export function saveSendSkuUpdata(form) {
//   return request({
//     url: `/wms-web/bas/sku/change/save`,
//     method: 'post',
//     data: form,
//   })
// }

// 查看批号列表
export function batchNumberList(form, current, size) {
    return request({
        url: `/wms-web/asn/batch/pages/${current}/${size}`,
        method: 'post',
        data: form
    })
}

// // 换发SKU列表
// export function getSendSku(data) {
//   return request({
//     url: `/wms-web/bas/sku/change/list`,
//     method: 'post',
//     data
//   })
// }

// 手动添加批号信息
export function saveHandSendSku(form) {
    return request({
        url: `/wms-web/asn/batch/save`,
        method: 'post',
        data: form,
    })
}

//获取附属品
export function getAccessorySkuId(id) {
    return request({
        url: `/wms-web/basSkuAccessory/getAccessorySkuId/${id}`,
        method: 'post'
    })
}

// 打印箱贴
export function basPrintSkuStandard(data) {
    return post(`/wms-web/bas/sku/printSkuStandard`, data, { responseType: 'arraybuffer' })
}

// 打印箱贴(单独页面)
export function skuPrintSkuLabel(data) {
    return post(`/wms-web/print/printSkuLabel`, data, { responseType: 'arraybuffer' })
}


// 换发SKU列表
export function getSendSku(data) {
    return request({
        url: `/wms-web/bas/sku/change/list`,
        method: 'post',
        data
    })
}

// 获取历史批号
export function getHistoricalBatchList(data) {
    return request({
        url: `/wms-web/asn/batch/getHistoricalBatchList`,
        method: 'post',
        data
    })
}


//sku下拉框
export function getSkuListApi(data) {
    return request({
      url: `/wms-web/bas/sku/list`,
      method: 'post',
      data:data
    })
  }
// sku下拉框
export function skuApi(data) {
    return request({
      url: `/wms-web/basSupplierPrioritySku/getSupplierDeliverSku`,
      method: 'post',
      data:data
    })
  }

//按类型打印产品标签
export function printProductLabels(data) {
    return post(`/wms-web/v2/print/printProductLabels`, data, { responseType: 'arraybuffer' })
}

//批号打印
export function printBatchApi(data) {
    return post(`/wms-web/asn/batch/printSkuBatch`, data, { responseType: 'arraybuffer' })
}