import { getBasRoute } from '@/api/bas/route'

const area = {
  state: {
    basRouteList: [],
  },
  actions: {
    getRouteList({commit, state}) {
      if (state.basRouteList.length === 0) {
        getBasRoute().then(res => {
          commit('setRouteList', res.data)
        }).catch(err => {
          console.log(err)
        })
      }
    },
    resetRouteList({commit, state}) {
      getBasRoute().then(res => {
        commit('setRouteList', res.data)
      }).catch(err => {
        console.log(err)
      })
    },
  },
  mutations: {
    setRouteList: (state, data) => {
      state.basRouteList = data
    }
  }
}

export default area
