import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/reset.css';
Vue.use(ElementUI);

import request from '@/utils/request'
Vue.prototype.$request = request
import { wmsDialog } from '@/components/dialog'
import { wmsTable } from '@/components/table'
import { wmsContainer, wmsFooter } from '@/components/container'
// import { iconTitle } from '@/components/title'
import '@/components/dialogDrag'
import '@/icons' // icon
import '@/assets/css/index.scss' //  css
import plugins from '@/utils/plugins'
Object.keys(plugins).forEach(key => Vue.prototype['$' + key] = plugins[key])

// register globally
import {
  wmsFormItem,
  wmsInput,
  wmsSelect,
  wmsDatePicker,
  wmsSwitch,
  wmsAutoComplate
} from '@/components/form'
import '@/permission' // permission control


Vue.component('wmsDialog', wmsDialog)
Vue.component('wmsTable', wmsTable)
Vue.component('wmsContainer', wmsContainer)
Vue.component('wmsFooter', wmsFooter)
Vue.component('wmsFormItem', wmsFormItem)
Vue.component('wmsInput', wmsInput)
Vue.component('wmsSelect', wmsSelect)
Vue.component('wmsDatePicker', wmsDatePicker)
Vue.component('wmsSwitch', wmsSwitch)
Vue.component('wmsAutoComplate', wmsAutoComplate)
// Vue.component('iconTitle', iconTitle)

Vue.prototype.getBlobUrl = (res) => {// 获取后台返回blob的url
  return new Promise((resolve, reject) => {
    let blob = new Blob([res])
    var reader = new FileReader()
    reader.onload = e => {
      try {// 是否是json数据
        let result = JSON.parse(e.target.result)
        reject(result)
      } catch (err) {// 正常的文件流
        let data = new Blob([res], {type: 'application/pdf;charset=UTF-8'})
        let url = window.URL.createObjectURL(data)
        resolve(url)
      }
    }
    reader.readAsText(blob)
  })
}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

Date.prototype.dateFtt = function dateFtt(fmt) { //author: meizz
  let o = {
    'M+': this.getMonth() + 1,                 //月份
    'd+': this.getDate(),                    //日
    'h+': this.getHours(),                   //小时
    'm+': this.getMinutes(),                 //分
    's+': this.getSeconds(),                 //秒
    'q+': Math.floor((this.getMonth() + 3) / 3), //季度
    'S': this.getMilliseconds()             //毫秒
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  for (let k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
    }
  }
  return fmt
}