<template>
  <wms-form-item v-if="span == 0"
    ref='formLable'
    :prop='prop'
    :label='label'
    :label-width='labelWidth'
    :required='required'
    :rules='rules'
    :error='error'
    :show-message='showMessage'
    :inline-message='inlineMessage'
    :size='formItemSize'>
    <el-input
      ref='wmsInput'
      :value="value"
      @input='val => $emit("input", noEmpty ? $removeAllSpace(val) : val)'
      @blur="iptBlur"
      @focus="iptFocus"
      @change="iptChange"
      @clear="iptClear"
      @keyup.enter.native="keyUpEnter"
      :type='type'
      :maxlength='maxlength'
      :minlength='minlength'
      :showWordLimit='showWordLimit'
      :placeholder='placeholder'
      :clearable='clearable'
      :show-password='showPassword'
      :disabled="disabled"
      :size='size'
      :prefix-icon='prefixIcon'
      :suffix-icon='suffixIcon'
      :rows='rows'
      :autosize='autosize'
      :autocomplete='autocomplete'
      :name='name'
      :readonly='readonly'
      :max='max'
      :min='min'
      :step='step'
      :resize='resize'
      :autofocus='autofocus'
      :form='form'
      :label='label'
      :tabindex='tabindex'
      :validate-event='validateEvent'>
      <template slot='prefix'>
        <slot name='prefix'></slot>  <!-- 输入框头部内容，只对 type="text" 有效 -->
      </template>
      <template slot='suffix'>
        <slot name='suffix'></slot>  <!-- 输入框尾部内容，只对 type="text" 有效 -->
      </template>
      <template slot='prepend'>
        <slot name='prepend'></slot>  <!-- 输入框前置内容，只对 type="text" 有效 -->
      </template>
      <template slot='append'>
        <slot name='append'></slot>  <!-- 输入框后置内容，只对 type="text" 有效 -->
      </template>
    </el-input>
  </wms-form-item>
  <el-col :span="span" v-else>
    <wms-form-item
      ref='formLable'
      :prop='prop'
      :label='label'
      :label-width='labelWidth'
      :required='required'
      :rules='rules'
      :error='error'
      :show-message='showMessage'
      :inline-message='inlineMessage'
      :size='formItemSize'
    >
      <el-input
        ref='wmsInput'
        :value="value"
        @input='val => $emit("input", noEmpty ? $removeAllSpace(val) : val)'
        @blur="iptBlur"
        @focus="iptFocus"
        @change="iptChange"
        @clear="iptClear"
        @keyup.enter.native="keyUpEnter"
        :type='type'
        :maxlength='maxlength'
        :minlength='minlength'
        :showWordLimit='showWordLimit'
        :placeholder='placeholder'
        :clearable='clearable'
        :show-password='showPassword'
        :disabled="disabled"
        :size='size'
        :prefix-icon='prefixIcon'
        :suffix-icon='suffixIcon'
        :rows='rows'
        :autosize='autosize'
        :autocomplete='autocomplete'
        :name='name'
        :readonly='readonly'
        :max='max'
        :min='min'
        :step='step'
        :resize='resize'
        :autofocus='autofocus'
        :form='form'
        :label='label'
        :tabindex='tabindex'
        :validate-event='validateEvent'
      >
        <template slot='prefix'>
          <slot name='prefix'></slot>  <!-- 输入框头部内容，只对 type="text" 有效 -->
        </template>
        <template slot='suffix'>
          <slot name='suffix'></slot>  <!-- 输入框尾部内容，只对 type="text" 有效 -->
        </template>
        <template slot='prepend'>
          <slot name='prepend'></slot>  <!-- 输入框前置内容，只对 type="text" 有效 -->
        </template>
        <template slot='append'>
          <slot name='append'></slot>  <!-- 输入框后置内容，只对 type="text" 有效 -->
        </template>
      </el-input>
    </wms-form-item>
  </el-col>
</template>

<script>
export default {
  name: 'wmsInput',
  props: {
    value: {// 绑定值
      required: true
    },
    noEmpty: {// 不允许空格
      type: Boolean,
      default: false
    },
    span: {// 当不为0时代表使用栅格
      type: Number,
      default: 0
    },
    // form-item
    prop: {// 使用表单方法时必填  传入Form组件的model中的字段
      type: String
    },
    label: {// 标签文本
      type: String
    },
    labelWidth: {// 表单域标签的的宽度，例如 '50px'。支持 auto。
      type: String
    },
    required: {// 是否必填，如不设置，则会根据校验规则自动生成
      type: Boolean,
      default: false
    },
    rules: {// 表单验证规则
      type: [Object, Array]
    },
    error: {// 表单域验证错误信息, 设置该值会使表单验证状态变为error，并显示该错误信息
      type: String
    },
    showMessage: {// 是否显示校验错误信息
      type: Boolean,
      default: true
    },
    inlineMessage: {// 以行内形式展示校验信息
      type: Boolean,
      default: false
    },
    formItemSize: {// 用于控制该表单域下组件的尺寸	medium / small / mini
      type: String
    },
    // input
    type: {// 类型
      type: String,
      default: 'text'
    },
    maxlength: {// 最大输入长度
      type: Number,
    },
    minlength: {// 最小输入长度
      type: Number,
    },
    showWordLimit: {// 是否显示输入字数统计， 只在type = text/textarea 是生效
      type: Boolean,
      default: false
    },
    placeholder: {// 输入框占位文本
      type: String
    },
    clearable: {// 是否可清空
      type: Boolean,
      default: true
    },
    showPassword: {// 是否显示切换密码图标
      type: Boolean,
      default: false
    },
    disabled: {//  禁用
      type: Boolean,
      default: false
    },
    size: {// 输入框尺寸 可选 medium/small/mini
      type: String,
    },
    prefixIcon: {// 输入框头部图标
      type: String
    },
    suffixIcon: {// 输入框尾部图标
      type: String
    },
    rows: {// 输入框行数，支队type='textarea'生效
      type: Number,
      default: 2
    },
    autosize: {// 自适应内容高度，只对 type="textarea" 有效，可传入对象，如，{ minRows: 2, maxRows: 6 }
      type: [Boolean, Object],
      default: () => {
        return false
      }
    },
    autocomplete: {// 原生属性，自动补全
      type: String,
      default: 'off'
    },
    name: {// 原生属性
      type: String
    },
    readonly: {// 原生属性，是否只读
      type: Boolean,
      default: false
    },
    max: {// 原生属性，设置最大值

    },
    min: {// 原生属性，设置最小值

    },
    step: {// 原生属性，设置输入字段的合法数字间隔

    },
    resize: {// 控制是否能被用户缩放  none, both, horizontal, vertical
      type: String
    },
    autofocus: {// 原生属性，自动获取焦点
      type: Boolean,
      default: false
    },
    form: {// 原生属性
      type: String
    },
    label: {// 输入框关联的label文字
      type: String
    },
    tabindex: {// 输入框的tabindex
      type: String
    },
    validateEvent: {// 输入时是否触发表单的校验
      type: Boolean,
      default: true
    }
  },
  data () {
    return {

    }
  },
  methods: {
    resetField() {
      this.$refs.formLable.resetField()
    },
    clearValidate() {
      this.$refs.formLable.clearValidate()
    },
    focus() {
      this.$refs.wmsInput.focus()
    },
    blur() {
      this.$refs.wmsInput.blur()
    },
    select() {
      this.$refs.wmsInput.select()
    },
    iptBlur(val) {
      this.$emit('blur', val)
    },
    iptFocus(val) {
      this.$emit('focus', val)
    },
    iptChange(val) {
      this.$emit('change', val)
    },
    iptClear() {
      this.$emit('clear')
    },
    keyUpEnter(val) {
      console.log('enter')
      this.$emit('keyupEnter', val)
    }
  }
}
</script>

<style>

</style>
