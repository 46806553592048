<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    v-dialogDrag
    v-loading='dialogLoading'
    @close="close"
    :modal-append-to-body='modalAppendToBody'
    :close-on-click-modal='closeOnClickModal'
    :append-to-body='appendToBody'
    :width="width"
    :center='center'>
    <div v-if="haveTitle" slot='title'>
      <slot name='title'></slot>
    </div>
    <div class="wmsDialogContent">
      <slot name="content"></slot>
      <slot></slot>
    </div>
    <div v-if="!haveFooter" slot="footer" :class='footerClass'>
      <el-button type="primary" @click="submit">确定</el-button>
      <el-button @click="close">关闭</el-button>
    </div>
    <div v-else slot="footer" :class='footerClass'>
      <slot name="dialogFooter"></slot>
    </div>
  </el-dialog>
</template>
<script>
  export default {
    name: 'wmsDialog',
    props: {
      dialogLoading: {
        type: Boolean,
        default: false
      },
      loadingText: {
        type: String,
        default: ''
      },
      dialogVisible: {
        type: Boolean,
        required: true
      },
      footerClass: {
        type: String,
        default: 'textAlignRight'
      },
      title: {
        type: String,
      },
      haveTitle: {
        type: Boolean,
        default: false
      },
      haveFooter: {
        type: Boolean,
        default: false
      },
      width: {
        type: String,
        default: '30%'
      },
      modalAppendToBody: {
        type: Boolean,
        default: true
      },
      appendToBody: {
        type: Boolean,
        default: false
      },
      center: {
        type: Boolean,
        default: true
      },
      closeOnClickModal: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        visible: this.dialogVisible
      }
    },
    watch: {
      dialogVisible(val) {
        this.visible = val
      }
    },
    methods: {
      close() {
        this.$emit('update:dialogVisible', false)
        this.$emit('dialogClose')
      },
      submit() {
        this.$emit('dialogCommit')
      }
    }
  }
</script>
<style scoped>
  .textAlignRight {
    text-align: right
  }
  .textAlignCenter{
     text-align: center
  }
</style>
